<template>
  <v-dialog v-model="dialog" width="1200" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn @click="getAttendance(1)" v-bind="attrs" v-on="on" icon>
        <v-icon color="success">mdi-flag</v-icon>
      </v-btn>
    </template>
    <v-card class="rounded-lg">
      <v-card-title class="pb-0">
        <v-row align="center" justify="space-between">
          <v-col class="px-0" cols="auto"></v-col>
          <v-col class="px-0" cols="auto">
            <p class="dialog-title">Посещение персоны</p>
          </v-col>
          <v-col class="px-0" cols="auto">
            <v-btn icon @click="dialog = false">
              <v-icon size="30" color="error">mdi-close-thick</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text style="padding: 15px 40px">
        <v-container fluid class="px-5">
          <v-row class="options" align="center">
            <v-col cols="auto" class="pl-4">
              <p class="title">Посещения</p>
            </v-col>
            <div style="width: 110px">
              <div class="input-wrapper">
                <v-select
                  @input="getAttendance"
                  :items="pagination.limits"
                  v-model="pagination.limit"
                  hide-spin-buttons
                  hide-selected
                  hide-details
                  outlined
                  dense
                />
              </div>
            </div>
            <v-spacer />
          </v-row>
          <v-row class="options" align="center">
            <v-col cols="auto" class="pl-4">
              <p class="label">Отображать:</p>
            </v-col>
            <div style="width: 110px">
              <div class="input-wrapper">
                <v-select
                  @input="getAttendance(1)"
                  :items="types"
                  v-model="type"
                  item-text="title"
                  item-value="value"
                  hide-spin-buttons
                  hide-details
                  outlined
                  dense
                />
              </div>
            </div>
            <v-col v-if="type === 'enter'" cols="auto" class="pl-4">
              <div class="d-flex flex-row align-center">
                <p class="label">Опоздал</p>
                <v-checkbox
                  class="pl-2"
                  @change="getAttendance(1)"
                  v-model="params.isLate"
                  hide-details
                />
              </div>
            </v-col>
            <v-col v-if="type === 'exit'" cols="auto" class="pl-4">
              <div class="d-flex flex-row align-center">
                <p class="label">Ушел раньше</p>
                <v-checkbox
                  class="pl-2"
                  @change="getAttendance(1)"
                  v-model="params.isEscaped"
                  hide-details
                />
              </div>
            </v-col>
            <v-spacer />
            <v-col cols="auto">
              <v-row align="center">
                <v-col cols="auto">
                  <p>Период:</p>
                </v-col>
                <v-col cols="auto">
                  <v-menu
                    v-model="menuFrom"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div class="input-wrapper" style="width: 160px">
                        <v-text-field
                          v-model="params.fromDate"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          hide-details
                        ></v-text-field>
                      </div>
                    </template>
                    <v-date-picker
                      v-model="params.fromDate"
                      @change="getAttendance"
                      :max="params.toDate"
                      scrollable
                      @input="menuFrom = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="auto">
                  <p>-</p>
                </v-col>
                <v-col cols="auto">
                  <v-menu
                    v-model="menuTo"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div class="input-wrapper" style="width: 160px">
                        <v-text-field
                          v-model="params.toDate"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          hide-details
                        ></v-text-field>
                      </div>
                    </template>
                    <v-date-picker
                      v-model="params.toDate"
                      @change="getAttendance"
                      :min="params.fromDate"
                      :max="new Date().toISOString().substr(0, 10)"
                      scrollable
                      @input="menuTo = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-data-table
            class="elevation-1"
            :headers="headers"
            :items="items"
            :loading="loading"
            :search="search"
            loading-text="Пожалуйста подождите, данные загружаются..."
            hide-default-footer
          >
            <template v-slot:item.date="{ item }">
              {{ new Date(item.datetime).toLocaleDateString("ru-RU") }}
            </template>
            <template v-slot:item.time="{ item }">
              {{
                new Date(item.datetime).toLocaleTimeString("ru-RU").slice(0, 5)
              }}
            </template>
            <template v-slot:item.fullName="{ item }">
              {{ item.attendance.person }}
            </template>
            <template v-slot:item.branch="{ item }">
              {{ item.attendance.branch }}
            </template>
            <template v-slot:item.log>
              <span v-if="type === 'enter'" class="enter-log">Вход</span>
              <span v-else class="exit-log">Выход</span>
            </template>
            <template v-slot:item.method="{ item }">
              <span
                v-if="item.passing_type === 'FACE_IDENT'"
                class="passing-face"
              >
                Биометрия
              </span>
              <span
                v-else-if="item.passing_type === 'CARD'"
                class="passing-card"
              >
                Карта
              </span>
            </template>
          </v-data-table>
          <custom-pagination
            @updatePage="getAttendance($event)"
            :pagination="pagination"
          />
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import CustomPagination from "@/components/CustomPagination";
export default {
  name: "AttendancePerson",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  components: { CustomPagination },
  data() {
    return {
      dialog: false,
      headers: [
        {
          text: "#",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: "Дата",
          align: "start",
          sortable: true,
          value: "date",
        },
        {
          text: "Время",
          align: "start",
          sortable: true,
          value: "time",
        },
        {
          text: "ФИО",
          align: "start",
          sortable: true,
          value: "fullName",
        },
        {
          text: "Филиал",
          align: "start",
          sortable: true,
          value: "branch",
        },
        {
          text: "Лог",
          align: "start",
          sortable: true,
          value: "log",
        },
        {
          text: "Способ",
          align: "start",
          sortable: true,
          value: "method",
        },
      ],
      pagination: {
        limit: 10,
        limits: [10, 50, 100],
        offset: 0,
        count: 0,
        page: 1,
        pages: 1,
      },
      params: {
        fromDate: new Date().toISOString().substr(0, 10),
        toDate: new Date(new Date().setDate(new Date().getDate() + 1))
          .toISOString()
          .substr(0, 10),
        person_id: null,
        branch: "",
        isLate: false,
        isEscaped: false,
      },
      menuFrom: false,
      menuTo: false,
      items: [],
      searchPerson: "",
      types: [
        {
          title: "Выход",
          value: "exit",
        },
        {
          title: "Вход",
          value: "enter",
        },
      ],
      type: "enter",
      branches: [],
      persons: [],
      loading: true,
      search: "",
    };
  },
  methods: {
    async getAttendance(page = 1) {
      if (this.type === "enter") this.params.isEscaped = false;
      else this.params.isLate = false;
      this.loading = true;
      this.items = [];
      this.pagination.page = page;
      this.pagination.offset =
        (this.pagination.page - 1) * this.pagination.limit;
      const url =
        this.type === "enter"
          ? "attendances/enter-logs/"
          : "attendances/exit-logs/";
      try {
        const response = await this.$axios.get(url, {
          params: {
            from_date: this.params.fromDate,
            to_date: this.params.toDate,
            limit: this.pagination.limit,
            offset: this.pagination.offset,
            is_late: this.params.isLate,
            is_escaped: this.params.isEscaped,
            person_id: this.id,
          },
        });
        this.items = response.data.results;
        this.loading = false;
        this.pagination.count = response.data.count;
        this.pagination.pages =
          Math.floor(this.pagination.count / this.pagination.limit) + 1;
      } catch (e) {
        this.loading = false;
        this.$root.$emit("showDefaultError");
      }
    },
    async getBranches() {
      try {
        const response = await this.$axios.get("organizations/branches/short/");
        response.data;
        this.branches = response.data;
      } catch (e) {
        this.$root.$emit("showDefaultError");
      }
    },
    async getPersons() {
      try {
        const response = await this.$axios.get("persons/short/");
        response.data;
        this.persons = response.data;
      } catch (e) {
        this.$root.$emit("showDefaultError");
      }
    },
  },
};
</script>

<style scoped></style>
