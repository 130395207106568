<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        class="create-item"
        color="#2CBF56"
        depressed
        rounded
      >
        Создать группу
        <v-icon class="ml-2" size="20">mdi-plus-circle</v-icon>
      </v-btn>
    </template>
    <v-card class="rounded-lg">
      <v-card-title class="pb-0">
        <v-row align="center" justify="space-between">
          <v-col class="px-0" cols="auto"></v-col>
          <v-col class="px-0" cols="auto">
            <p class="dialog-title">Создать группу</p>
          </v-col>
          <v-col class="px-0" cols="auto">
            <v-btn icon @click="resetState">
              <v-icon size="30" color="error">mdi-close-thick</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text style="padding: 15px 40px">
        <v-form ref="form" v-model="valid">
          <div class="input-wrapper">
            <label for="device_key"
              >Наименование группы<span class="red--text">*</span>
            </label>
            <v-text-field
              autocomplete="off"
              v-model="groups.title"
              id="groups_key"
              :rules="rules.required"
              outlined
              dense
            />
          </div>
          <div class="input-wrapper">
            <label for="branch">Филиал <span class="red--text">*</span> </label>
            <v-select
              :items="branch"
              v-model="groups.branch_id"
              id="branch_id"
              item-text="title"
              item-value="id"
              :rules="rules.required"
              hint="Поле обязательно для заполнения"
              outlined
              persistent-hint
              clearable
            />
          </div>
          <v-btn
            @click="createGroups"
            :disabled="!valid"
            :loading="loading"
            class="save-btn"
            depressed
            >Сохранить</v-btn
          >
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CreateGroups",
  props: {
    branch: {
      type: Array,
      required: true,
    },
  },
  data: () => {
    return {
      groups: {},
      dialog: false,
      loading: false,
      valid: false,
      rules: {
        required: [(v) => !!v || "Поле обязательно для заполнения"],
      },
    };
  },
  methods: {
    resetState() {
      this.groups = {};
      this.loading = false;
      this.dialog = false;
      this.$refs.form.reset();
    },
    async createGroups() {
      this.loading = true;
      try {
        await this.$axios.post("groups/create/", this.groups);
        this.$root.$emit("showCustomSuccess", "Группа успешно создана!");
        this.$emit("refreshData");
      } catch (result) {
        this.$root.$emit("showCustomError", result);
      } finally {
        this.resetState();
      }
    },
  },
};
</script>

<style scoped>
.create-item {
  width: 240px;
  height: 41px;

  font-family: Gilroy-Medium, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #ffffff;
  text-transform: none !important;
}
.dialog-title {
  font-family: Gilroy-Medium, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 31px;
  text-align: center;
  color: #3474d3;
}
</style>
