<template>
  <v-container fluid class="px-5">
    <v-row class="options" align="center">
      <v-col cols="auto" class="pl-7">
        <p class="title">Филиал</p>
      </v-col>
      <div style="width: 110px">
        <v-col cols="12">
          <div class="input-wrapper">
            <v-select
              @input="getBranches(1)"
              :items="pagination.limits"
              v-model="pagination.limit"
              hide-spin-buttons
              hide-selected
              hide-details
              outlined
            />
          </div>
        </v-col>
      </div>
      <v-spacer />
      <v-col cols="auto">
        <CreateBranch
          @refreshData="getBranches"
          :cities="cities"
          v-if="canUser('branch-c')"
        />
      </v-col>
    </v-row>
    <v-data-table
      class="elevation-1"
      :headers="headers"
      :items="branches"
      :items-per-page="pagination.limit"
      :loading="loading"
      :search="search"
      loading-text="Пожалуйста подождите, данные загружаются..."
      hide-default-footer
    >
      <template v-slot:item.status="{ item }">
        <span
          :class="item.status === 'No Active' ? 'red--text' : 'green--text'"
        >
          {{ item.status }}</span
        >
      </template>
      <template v-slot:item.actions="{ item }">
        <DetailBranch v-if="canUser('branch-rd')" :id="item.id" />
        <UpdateBranch
          v-if="canUser('branch-u')"
          @refreshData="getBranches"
          :branches="branches"
          :branch-default="item"
          :cities="cities"
        />
        <delete-item
          v-if="canUser('branch-d')"
          :delete-item="deleteItem(item)"
          @deleteBranch="deleteBranch(item)"
        />
      </template>
    </v-data-table>
    <custom-pagination
      @updatePage="getBranches($event)"
      :pagination="pagination"
    />
  </v-container>
</template>

<script>
import DeleteItem from "@/components/DeleteItem";
import UpdateBranch from "@/components/Branches/UpdateBranch";
import CreateBranch from "@/components/Branches/CreateBranch";
import CustomPagination from "@/components/CustomPagination";
import DetailBranch from "@/components/Branches/DetailBranch";
export default {
  name: "Branches",
  components: {
    DetailBranch,
    CreateBranch,
    UpdateBranch,
    DeleteItem,
    CustomPagination,
  },
  data: () => {
    return {
      loading: true,
      cities: [],
      branches: [],
      headers: [
        {
          text: "Организация",
          align: "start",
          sortable: true,
          value: "organization.title",
        },
        {
          text: "Филиал",
          align: "start",
          sortable: true,
          value: "title",
        },
        {
          text: "Город",
          align: "start",
          sortable: false,
          value: "city.name",
        },
        {
          text: "БИН",
          align: "center",
          sortable: false,
          value: "bin",
        },
        {
          text: "Статус",
          align: "center",
          sortable: false,
          value: "status",
        },
        {
          text: "Действие",
          align: "center",
          sortable: false,
          value: "actions",
        },
      ],

      pagination: {
        limit: 10,
        limits: [10, 50, 100],
        offset: 0,
        count: 200,
        page: 1,
        pages: 10,
      },
      search: "",
    };
  },
  methods: {
    async getBranches(page = 1) {
      this.loading = true;
      this.branches = [];
      this.pagination.page = page;
      this.pagination.offset =
        (this.pagination.page - 1) * this.pagination.limit;
      try {
        const response = await this.$axios.get("organizations/branches/list/", {
          params: {
            limit: this.pagination.limit,
            offset: this.pagination.offset,
          },
        });
        this.branches = response.data.results;
        this.loading = false;
        this.pagination.count = response.data.count;
        this.pagination.pages =
          Math.floor(this.pagination.count / this.pagination.limit) + 1;
      } catch (e) {
        this.loading = false;
        this.$root.$emit("showDefaultError");
      }
    },

    async deleteBranch(branch) {
      try {
        await this.$axios.post(`organizations/branches/${branch.id}/delete/`);
        this.$root.$emit("showCustomSuccess", "Филиал успешно удален!");
        await this.getBranches(this.pagination.page);
      } catch (e) {
        this.$root.$emit(
          "showCustomError",
          `Ошибка во время удаления филиала ${branch.id}`
        );
      }
    },
    async getCities() {
      try {
        const response = await this.$axios.get("geo/cities/short/");
        this.cities = response.data;
      } catch (e) {
        this.$root.$emit("showCustomError", `Ошибка при получение городов`);
      }
    },
    deleteItem(item) {
      return {
        name: item.id,
        action: "deleteBranch",
      };
    },
  },
  mounted() {
    this.getBranches();
    this.getCities();
  },
};
</script>

<style scoped lang="scss"></style>
