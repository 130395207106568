<template>
  <v-dialog v-model="dialog" width="800" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn @click="getDetails" v-bind="attrs" v-on="on" icon>
        <v-icon color="success">mdi-eye-outline</v-icon>
      </v-btn>
    </template>
    <v-card class="rounded-lg">
      <v-card-title class="pb-0">
        <v-row align="center" justify="space-between">
          <v-col class="px-0" cols="auto"></v-col>
          <v-col class="px-0" cols="auto">
            <p class="dialog-title">Подробный просмотр</p>
          </v-col>
          <v-col class="px-0" cols="auto">
            <v-btn icon @click="dialog = false">
              <v-icon size="30" color="error">mdi-close-thick</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text style="padding: 15px 40px">
        <v-row>
          <v-col cols="6">
            <div class="input-wrapper">
              <label>Название Филиала <span class="red--text">*</span> </label>
              <v-text-field
                disabled
                autocomplete="off"
                v-model="branch.title"
                outlined
                dense
              />
            </div>
            <div class="input-wrapper">
              <label
                >БИН филиала
                <span class="red--text">*</span>
              </label>
              <v-text-field
                disabled
                autocomplete="off"
                v-model="branch.bin"
                outlined
                dense
              />
            </div>
            <div class="input-wrapper">
              <label>Город <span class="red--text">*</span> </label>
              <v-text-field
                disabled
                v-model="branch.city"
                outlined
                dense
              ></v-text-field>
            </div>
            <div class="input-wrapper">
              <label>Статус </label>
              <v-text-field
                disabled
                autocomplete="off"
                v-model="branch.status"
                outlined
                dense
              />
            </div>
            <div class="d-flex flex-row align-center justify-space-between">
              <div class="input-wrapper" style="width: 58%">
                <label>Улица </label>
                <v-text-field
                  disabled
                  autocomplete="off"
                  v-model="branch.street"
                  outlined
                  dense
                />
              </div>
              <div class="input-wrapper" style="width: 38%">
                <label>Номер здания </label>
                <v-text-field
                  disabled
                  autocomplete="off"
                  v-model="branch.building_number"
                  outlined
                  dense
                />
              </div>
            </div>
            <div class="d-flex flex-row align-center justify-space-between">
              <div class="input-wrapper" style="width: 58%">
                <label>Долгота</label>
                <v-text-field
                  disabled
                  autocomplete="off"
                  v-model="branch.longitude"
                  outlined
                  dense
                />
              </div>
              <div class="input-wrapper" style="width: 38%">
                <label>Ширина </label>
                <v-text-field
                  disabled
                  autocomplete="off"
                  v-model="branch.latitude"
                  outlined
                  dense
                />
              </div>
            </div>
          </v-col>
          <v-col cols="6">
            <div class="input-wrapper my-3">
              <label class="ma-0">ID филиала: </label>
              <p class="detail-text">{{ branch.id }}</p>
            </div>
            <div class="input-wrapper my-3">
              <label class="ma-0">Организация: </label>
              <p class="detail-text">{{ branch.organization }}</p>
            </div>
            <div class="input-wrapper my-3">
              <label class="ma-0">Дата создания: </label>
              <p class="detail-text">
                {{ new Date(branch.created_at).toLocaleDateString("ru-RU") }}
                {{ new Date(branch.created_at).toLocaleTimeString("ru-RU") }}
              </p>
            </div>
            <div class="input-wrapper my-3">
              <label class="ma-0">Дата обновления: </label>
              <p class="detail-text">
                {{ new Date(branch.updated_at).toLocaleDateString("ru-RU") }}
                {{ new Date(branch.updated_at).toLocaleTimeString("ru-RU") }}
              </p>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "DetailBranch",
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      branch: {},
      dialog: false,
    };
  },
  methods: {
    async getDetails() {
      try {
        const response = await axios.get(
          `/organizations/branches/${this.id}/details/`
        );
        this.branch = response.data;
      } catch (e) {
        this.$root.$emit("showCustomError", `Ошибка при открытие`);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.dialog-title {
  font-family: Gilroy-Medium, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 31px;
  text-align: center;
  color: #3474d3;
}
.preview {
  position: relative;
  height: 125px;
  width: 125px;

  .delete-btn {
    position: absolute;
    right: 0;
    top: 0;
  }
}
.detail-text {
  font-family: "Gilroy-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}
</style>
