var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"600","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn-text",staticStyle:{"text-transform":"none"},attrs:{"color":"#2CBF56","rounded":""}},'v-btn',attrs,false),on),[_vm._v("Создать отчёт "),_c('v-icon',{staticClass:"ml-2",attrs:{"size":"20"}},[_vm._v("mdi-plus-circle")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"rounded-xl",staticStyle:{"overflow":"hidden"}},[_c('v-row',[_c('v-col',[_c('v-spacer')],1),_c('v-card-title',{staticClass:"title_color",staticStyle:{"padding-top":"20px"}},[_vm._v(" Создать отчёт ")]),_c('v-col',{staticStyle:{"display":"flex","flex-direction":"row-reverse","margin-right":"30px","margin-top":"10px"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.resetState}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',{staticStyle:{"padding":"15px 40px"}},[_c('v-form',{attrs:{"valid":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{attrs:{"justify":"space-around"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('label',[_vm._v("Дата начала ")]),_c('div',{staticClass:"input-wrapper",staticStyle:{"width":"150px"}},[_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","readonly":"","outlined":""},model:{value:(_vm.params.fromDate),callback:function ($$v) {_vm.$set(_vm.params, "fromDate", $$v)},expression:"params.fromDate"}},'v-text-field',attrs,false),on))],1)]}}]),model:{value:(_vm.menuFrom),callback:function ($$v) {_vm.menuFrom=$$v},expression:"menuFrom"}},[_c('v-date-picker',{attrs:{"scrollable":""},on:{"input":function($event){_vm.menuFrom = false}},model:{value:(_vm.params.fromDate),callback:function ($$v) {_vm.$set(_vm.params, "fromDate", $$v)},expression:"params.fromDate"}})],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('label',[_vm._v("Дата конца ")]),_c('div',{staticClass:"input-wrapper",staticStyle:{"width":"160px"}},[_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","readonly":"","outlined":""},model:{value:(_vm.params.toDate),callback:function ($$v) {_vm.$set(_vm.params, "toDate", $$v)},expression:"params.toDate"}},'v-text-field',attrs,false),on))],1)]}}]),model:{value:(_vm.menuTo),callback:function ($$v) {_vm.menuTo=$$v},expression:"menuTo"}},[_c('v-date-picker',{attrs:{"scrollable":""},on:{"input":function($event){_vm.menuTo = false}},model:{value:(_vm.params.toDate),callback:function ($$v) {_vm.$set(_vm.params, "toDate", $$v)},expression:"params.toDate"}})],1)],1)],1),_c('v-col',{staticStyle:{"padding-left":"50px"}},[_c('div',{staticClass:"input-wrapper"},[_c('label',[_vm._v("Отчет по Филиалу ? ")]),_c('v-checkbox',{staticClass:"pl-2",attrs:{"hide-details":""},on:{"click":_vm.changeIsGroup}})],1)]),(_vm.isGroup === false)?_c('v-col',{staticStyle:{"padding-left":"50px"}},[_c('div',{staticClass:"input-wrapper"},[_c('label',[_vm._v("Группа ")]),_c('v-select',{attrs:{"items":_vm.groups,"item-text":"title","item-value":"id","clearable":"","hide-selected":"","hide-details":"","outlined":""},model:{value:(_vm.group_id),callback:function ($$v) {_vm.group_id=$$v},expression:"group_id"}})],1)]):_vm._e(),_c('v-col',[_c('v-btn',{staticClass:"white--text",staticStyle:{"text-transform":"none"},attrs:{"block":"","disabled":!_vm.valid,"color":"#3474D3"},on:{"click":_vm.creteReports}},[_vm._v("Сохранить")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }