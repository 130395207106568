<template>
  <v-container fluid class="px-5">
    <v-row class="options" align="center">
      <v-col cols="auto" class="pl-4">
        <p class="title">Пользователи</p>
      </v-col>
      <div style="width: 110px">
        <div class="input-wrapper">
          <v-select
            @input="getUsers(1)"
            :items="pagination.limits"
            v-model="pagination.limit"
            hide-spin-buttons
            hide-selected
            hide-details
            outlined
          />
        </div>
      </div>
      <v-spacer />
      <v-col cols="auto">
        <create-user
          v-if="canUser('user-c')"
          @refreshData="getUsers(pagination.page)"
          :branches="branches"
          :roles="roles"
        />
      </v-col>
    </v-row>
    <v-data-table
      class="elevation-1"
      :headers="headers"
      :items="items"
      :loading="loading"
      loading-text="Пожалуйста подождите, данные загружаются..."
      hide-default-footer
      multi-sort
    >
      <template v-slot:item.fullName="{ item }">
        {{ item.last_name }} {{ item.first_name }} {{ item.patronymic }}
      </template>
      <template
        v-if="canUser('user-rd') || canUser('user-u') || canUser('user-d')"
        v-slot:item.actions="{ item }"
      >
        <DetailUsers v-if="canUser('user-rd')" :id="item.id" />
        <update-user
          v-if="canUser('user-u')"
          @refreshData="getUsers"
          :user-default="item"
          :branches="branches"
          :roles="roles"
        />
        <delete-item
          v-if="canUser('user-d')"
          @deleteUser="deleteUser(item)"
          :delete-item="deleteItem(item)"
        />
      </template>
    </v-data-table>
    <custom-pagination
      @updatePage="getUsers($event)"
      :pagination="pagination"
    />
  </v-container>
</template>

<script>
import CreateUser from "@/components/Users/CreateUser";
import CustomPagination from "@/components/CustomPagination";
import DeleteItem from "@/components/DeleteItem";
import UpdateUser from "@/components/Users/UpdateUser";
import DetailUsers from "@/components/Users/DetailUsers";
export default {
  name: "Users",
  components: {
    DetailUsers,
    UpdateUser,
    DeleteItem,
    CustomPagination,
    CreateUser,
  },
  data() {
    return {
      headers: [
        {
          text: "Филиал",
          align: "start",
          sortable: true,
          value: "branch.title",
        },
        {
          text: "ФИО",
          align: "start",
          sortable: true,
          value: "fullName",
        },
        {
          text: "ИИН",
          align: "start",
          sortable: true,
          value: "iin",
        },
        {
          text: "Номер телефона",
          align: "start",
          sortable: true,
          value: "phone",
        },
        {
          text: "Роль",
          align: "start",
          sortable: true,
          value: "role.name",
        },
        {
          text: "Действие ",
          align: "start",
          sortable: true,
          value: "actions",
        },
      ],
      pagination: {
        limit: 10,
        limits: [10, 50, 100],
        offset: 0,
        count: 0,
        page: 1,
        pages: 1,
      },
      branches: [],
      roles: [],
      items: [],
      loading: true,
      search: "",
    };
  },
  methods: {
    async getUsers(page = 1) {
      this.loading = true;
      this.items = [];
      this.pagination.page = page;
      this.pagination.offset =
        (this.pagination.page - 1) * this.pagination.limit;
      try {
        const response = await this.$axios.get("users/", {
          params: {
            limit: this.pagination.limit,
            offset: this.pagination.offset,
          },
        });
        this.items = response.data.results;
        this.loading = false;
        this.pagination.count = response.data.count;
        this.pagination.pages =
          Math.floor(this.pagination.count / this.pagination.limit) + 1;
      } catch (e) {
        this.loading = false;
        this.$root.$emit("showDefaultError");
      }
    },
    async deleteUser(item) {
      try {
        await this.$axios.post(`users/${item.id}/delete/`);
        this.$root.$emit("showCustomSuccess", `Объект успешно удален`);
        await this.getUsers();
      } catch (e) {
        this.$root.$emit(
          "showCustomError",
          `Произошла ошибка, попробуйте позже`
        );
      }
    },
    async getBranches() {
      try {
        const response = await this.$axios.get("organizations/branches/short/");
        response.data;
        this.branches = response.data;
      } catch (e) {
        this.$root.$emit("showDefaultError");
      }
    },
    async getRoles() {
      try {
        const response = await this.$axios.get("privileges/roles/short/");
        response.data;
        this.roles = response.data;
      } catch (e) {
        this.$root.$emit("showDefaultError");
      }
    },
    deleteItem(item) {
      return {
        name: item.id,
        action: "deleteUser",
      };
    },
  },
  async created() {
    await this.getUsers();
    await this.getBranches();
    await this.getRoles();
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  margin-left: 40px;

  .options {
    display: flex;
    flex-direction: row;
    align-items: center;

    .custom-select {
      width: 100px !important;
      max-width: 100px !important;
    }

    .title {
      font-family: "Gilroy-Medium", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 26px;
      line-height: 31px;
      color: #2c2c32;
    }
  }
}
</style>
