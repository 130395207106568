<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn @click="copyObject" v-bind="attrs" v-on="on" icon>
        <v-icon color="blue">mdi-square-edit-outline</v-icon>
      </v-btn>
    </template>
    <v-card class="rounded-lg">
      <v-card-title class="pb-0">
        <v-row align="center" justify="space-between">
          <v-col class="px-0" cols="auto"></v-col>
          <v-col class="px-0" cols="auto">
            <p class="dialog-title">Редактирование пользователя</p>
          </v-col>
          <v-col class="px-0" cols="auto">
            <v-btn icon @click="resetState()">
              <v-icon size="30" color="error">mdi-close-thick</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text style="padding: 15px 40px">
        <v-form v-model="valid">
          <div class="d-flex flex-row align-center justify-space-between">
            <div class="input-wrapper" style="width: 48%">
              <label for="first_name"
                >Имя <span class="red--text">*</span>
              </label>
              <v-text-field
                autocomplete="given-name"
                placeholder="Введите имя пользователя"
                v-model="updatedUser.first_name"
                id="first_name"
                :rules="rules.required"
                outlined
                dense
              />
            </div>
            <div class="input-wrapper" style="width: 48%">
              <label for="last_name"
                >Фамилия <span class="red--text">*</span>
              </label>
              <v-text-field
                autocomplete="family-name"
                placeholder="Введите фамилию пользователя"
                v-model="updatedUser.last_name"
                id="last_name"
                :rules="rules.required"
                outlined
                dense
              />
            </div>
          </div>
          <div class="input-wrapper">
            <label for="patronymic">Отчество </label>
            <v-text-field
              autocomplete="additional-name"
              placeholder="Введите отчество персоны"
              v-model="updatedUser.patronymic"
              id="patronymic"
              outlined
              dense
            />
          </div>
          <div class="input-wrapper">
            <label for="username"
              >Логин <span class="red--text">*</span>
            </label>
            <v-text-field
              autocomplete="username"
              placeholder="Введите логин пользователя"
              v-model="updatedUser.username"
              id="username"
              class="test"
              outlined
              dense
            />
          </div>
          <div class="input-wrapper">
            <label for="email"
              >Электронная почта <span class="red--text">*</span>
            </label>
            <v-text-field
              autocomplete="email"
              placeholder="Введите адрес электронной почты"
              v-model="updatedUser.email"
              id="email"
              outlined
              dense
            />
          </div>
          <div class="helper-container">
            <p class="helper-text">
              На почту пользователю будет выслан сгенерированный пароль.
              Пользователь всегда сможет изменить пароль.
            </p>
          </div>
          <div class="input-wrapper">
            <label for="phone"
              >Номер телефона <span class="red--text">*</span>
            </label>
            <v-text-field
              placeholder="Введите номер в формате +71112223344"
              v-model="updatedUser.phone"
              :rules="rules.mobile_phone"
              id="phone"
              hide-spin-buttons
              outlined
              dense
            />
          </div>
          <div class="input-wrapper">
            <label for="branch">Филиал <span class="red--text">*</span> </label>
            <v-select
              placeholder="Выберите филиал"
              v-model="updatedUser.branch_bin"
              :items="branches"
              item-value="bin"
              item-text="title"
              :rules="rules.select"
              id="branch"
              outlined
              dense
            />
          </div>
          <div class="input-wrapper">
            <label for="role">Роль <span class="red--text">*</span> </label>
            <v-select
              placeholder="Выберите филиал"
              v-model="updatedUser.role_id"
              :items="roles"
              item-value="id"
              item-text="name"
              :rules="rules.select"
              id="role"
              outlined
              dense
            />
          </div>
          <div class="input-wrapper">
            <label for="iin">ИИН <span class="red--text">*</span> </label>
            <v-text-field
              placeholder="Введите ИИН пользователя"
              v-model="updatedUser.iin"
              :rules="rules.individual_identifier"
              id="iin"
              counter
              maxlength="12"
              hide-spin-buttons
              outlined
              dense
            />
          </div>
          <div class="input-wrapper" style="width: 48%">
            <label for="telegram_id">Телеграм ID </label>
            <v-text-field
              placeholder="Введите телеграм ID персоны"
              v-model="updatedUser.telegram_id"
              id="telegram_id"
              outlined
              dense
            />
          </div>
          <!--          <div class="input-wrapper">-->
          <!--            <label for="facePhoto"-->
          <!--              >Фотография пользователя <span class="red&#45;&#45;text">*</span>-->
          <!--            </label>-->
          <!--            <label class="input" v-if="!image">-->
          <!--              <input-->
          <!--                @change="onFileChange($event)"-->
          <!--                type="file"-->
          <!--                accept="image/png, image/jpeg"-->
          <!--              />-->
          <!--              Загрузить-->
          <!--              <v-icon color="#3474d3" size="20" class="pl-2"-->
          <!--                >mdi-arrow-down-circle-outline</v-icon-->
          <!--              >-->
          <!--            </label>-->
          <!--            <div class="preview" v-else>-->
          <!--              <v-img height="125" width="125" :src="image" />-->
          <!--              <v-btn class="delete-btn" @click="removeImage" icon>-->
          <!--                <v-icon color="error">mdi-delete</v-icon>-->
          <!--              </v-btn>-->
          <!--            </div>-->
          <!--          </div>-->
        </v-form>
        <v-btn
          @click="update"
          :loading="loading"
          :disabled="!valid"
          class="save-btn"
          depressed
          >Сохранить</v-btn
        >
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "UpdateUser",
  props: {
    userDefault: {
      type: Object,
      required: true,
    },
    branches: {
      type: Array,
      required: true,
    },
    roles: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      valid: false,
      loading: false,
      image: "",
      updatedUser: {
        patronymic: "",
      },
      rules: {
        select: [
          (v) => {
            if (v) return true;
            return "Поле обязательно для заполнения";
          },
        ],
        required: [
          (v) => {
            if (v) if (0 < v.length <= 255) return true;
            return "Поле обязательно для заполнения";
          },
        ],
        mobile_phone: [
          (v) => {
            if (v && v?.length > 0) {
              return (
                /^[+7]\d{10}/.test(v) ||
                "Номер должен соответсовать следующему формату: +77001112233"
              );
            }
            return "Поле обязательно для заполнения";
          },
        ],
        individual_identifier: [
          (v) => {
            if (v && v.length > 0) {
              return /^\d{12}/.test(v) || "Введите корректный ИИН";
            }
            return "Поле обязательно для заполнения";
          },
        ],
      },
    };
  },
  methods: {
    resetState() {
      this.dialog = false;
      this.loading = false;
    },
    copyObject() {
      Object.assign(this.updatedUser, this.userDefault);
      this.updatedUser.branch_bin = this.updatedUser.branch.bin;
      this.updatedUser.role_id = this.updatedUser.role.id;
      // delete this.updatedUser.username;
      delete this.updatedUser.branch;
      delete this.updatedUser.role;
    },
    async update() {
      this.loading = true;
      try {
        await this.$axios.post(`/users/${this.userDefault.id}/update/`, {
          ...this.updatedUser,
        });
        this.$root.$emit(
          "showCustomSuccess",
          `Пользователь ${this.updatedUser.username} был успешно обновлен`
        );
        this.$emit("refreshData");
      } catch (result) {
        this.$root.$emit("showCustomError", result);
      } finally {
        this.resetState();
      }
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      const reader = new FileReader();
      const vm = this;
      this.updatedUser.face_photo = file;

      reader.onload = (e) => {
        vm.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    removeImage() {
      this.image = "";
      this.updatedUser.face_photo = "";
    },
  },
};
</script>

<style scoped lang="scss">
.create-item {
  width: 240px;
  height: 41px;

  font-family: Gilroy-Medium, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #ffffff;
  text-transform: none !important;
}
.dialog-title {
  font-family: Gilroy-Medium, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 31px;
  text-align: center;
  color: #3474d3;
}
.preview {
  position: relative;
  height: 125px;
  width: 125px;

  .delete-btn {
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>
