import axios from "axios";
import store from "@/store/index";
import router from "@/router";

axios.interceptors.response.use(null, (error) => {
  const {
    config,
    response: { status, data },
  } = error;
  const refresh = localStorage.getItem("refresh");
  if (status === 401 && config.url !== "/token/refresh/" && refresh) {
    return refreshTokens(refresh);
  }
  const errors = data.extra;
  let result = "";
  if (errors) {
    for (let error in errors) {
      result += `${error}: ${errors[error]} \n`;
    }
    return Promise.reject(result);
  }
  return Promise.reject(error);
});

const setup = () => {
  axios.defaults.baseURL = `${process.env.VUE_APP_BACKEND_URL}/api/`;
  axios.defaults.timeout = 55 * 1000;
  axios.cancelToken = axios.CancelToken;
  updateAuthorizationHeader();
};

async function refreshTokens(refresh) {
  removeAuthorizationHeader();
  try {
    const response = await axios.post("/token/refresh/", {
      refresh: refresh,
    });
    store.commit("saveTokens", response.data);
    updateAuthorizationHeader();
    location.reload();
  } catch (e) {
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    await router.push({ name: "login" });
  }
}

export function updateAuthorizationHeader(access = store.state.tokens.access) {
  if (access) {
    axios.defaults.headers.common["Authorization"] = `JWT ${access}`;
  }
}

export function removeAuthorizationHeader() {
  delete axios.defaults.headers.common["Authorization"];
}

export default {
  install: function (Vue) {
    Object.defineProperty(Vue.prototype, "$axios", { value: axios });
    Vue.mixin({
      created() {
        setup();
      },
    });
  },
};
