<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn @click="copyObject" v-bind="attrs" v-on="on" icon>
        <v-icon color="blue">mdi-square-edit-outline</v-icon>
      </v-btn>
    </template>
    <v-card class="rounded-lg">
      <v-card-title class="pb-0">
        <v-row align="center" justify="space-between">
          <v-col class="px-0" cols="auto"></v-col>
          <v-col class="px-0" cols="auto">
            <p class="dialog-title">Редактирование гостя</p>
          </v-col>
          <v-col class="px-0" cols="auto">
            <v-btn icon @click="resetState">
              <v-icon size="30" color="error">mdi-close-thick</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text style="padding: 15px 40px">
        <v-form ref="form" v-model="valid">
          <div class="d-flex flex-row justify-space-between">
            <div class="input-wrapper" style="width: 48%">
              <label for="first_name"
                >Имя <span class="red--text">*</span>
              </label>
              <v-text-field
                autocomplete="given-name"
                placeholder="Введите имя персоны"
                v-model="updatedGuest.first_name"
                id="first_name"
                :rules="rules.required"
                outlined
                dense
              />
            </div>
            <div class="input-wrapper" style="width: 48%">
              <label for="last_name"
                >Фамилия <span class="red--text">*</span>
              </label>
              <v-text-field
                autocomplete="family-name"
                placeholder="Введите фамилию персоны"
                v-model="updatedGuest.last_name"
                id="last_name"
                :rules="rules.required"
                outlined
                dense
              />
            </div>
          </div>
          <div class="input-wrapper">
            <label for="patronymic">Отчество </label>
            <v-text-field
              autocomplete="additional-name"
              placeholder="Введите фамилию персоны"
              v-model="updatedGuest.patronymic"
              id="patronymic"
              outlined
              dense
            />
          </div>
          <div class="input-wrapper">
            <label for="iin">ИИН <span class="red--text">*</span> </label>
            <v-text-field
              placeholder="Введите ИИН пользователя"
              v-model="updatedGuest.iin"
              :rules="rules.individual_identifier"
              id="iin"
              counter
              maxlength="12"
              hide-spin-buttons
              outlined
              dense
            />
          </div>
          <div class="input-wrapper">
            <label for="branch">Филиал <span class="red--text">*</span> </label>
            <v-select
              @change="getGroups"
              placeholder="Выберите филиал"
              v-model="updatedGuest.branch_id"
              :rules="rules.required"
              :items="branches"
              item-text="title"
              item-value="id"
              id="branch"
              outlined
              dense
            />
          </div>
          <div class="input-wrapper">
            <label for="group">Группа</label>
            <v-select
              placeholder="Выберите группу"
              v-model="updatedGuest.group_id"
              :items="groups"
              item-text="title"
              item-value="id"
              id="group"
              outlined
              dense
            />
          </div>
          <div class="d-flex flex-row justify-space-between">
            <div class="input-wrapper" style="width: 48%">
              <label for="telegram_id">Телеграм ID </label>
              <v-text-field
                placeholder="Введите имя персоны"
                v-model="updatedGuest.telegram_id"
                id="telegram_id"
                type="number"
                hide-spin-buttons
                outlined
                dense
              />
            </div>
            <div class="input-wrapper" style="width: 48%">
              <label for="card_id">ID Карты </label>
              <v-text-field
                placeholder="Введите фамилию персоны"
                v-model="updatedGuest.card_id"
                id="card_id"
                outlined
                dense
              />
            </div>
          </div>
          <div class="d-flex flex-row justify-space-between">
            <div class="input-wrapper" style="width: 48%">
              <label for="date">Дата удаления с планшета</label>
              <v-text-field
                placeholder="Введите дату"
                v-model="date"
                id="date"
                outlined
                dense
              />
            </div>
            <div class="input-wrapper" style="width: 48%">
              <label for="time">Время удаления с планшета</label>
              <v-text-field
                placeholder="Введите дату"
                v-model="time"
                id="time"
                outlined
                dense
              />
            </div>
          </div>
        </v-form>
        <v-btn
          @click="update"
          :disabled="!valid"
          :loading="loading"
          class="save-btn"
          depressed
          >Сохранить</v-btn
        >
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "UpdateGuest",
  props: {
    id: {
      type: String,
      required: true,
    },
    guest: {
      type: Object,
      required: true,
    },
    branches: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      valid: false,
      loading: false,
      groups: [],
      devices: [],
      updatedGuest: {},
      searchDevice: "",
      date: null,
      time: null,
      rules: {
        select: [
          (v) => {
            if (!!v && Object.entries(v).length > 0) return true;
            return "Поле обязательно для заполнения";
          },
        ],
        required: [
          (v) => {
            if (v) if (0 < v.length <= 255) return true;
            return "Поле обязательно для заполнения";
          },
        ],
      },
    };
  },
  methods: {
    copyObject() {
      this.getDetails();
      this.getGroups();
      this.getDevices();
    },
    resetState() {
      this.groups = [];
      this.devices = [];
      this.updatedGuest = {};
      this.loading = false;
      this.$refs.form.reset();
      this.dialog = false;
    },
    async update() {
      this.updatedGuest.expire_at = `${this.date}T${this.time}`;
      this.loading = true;
      try {
        await this.$axios.post(`/persons/${this.guest.id}/update/`, {
          ...this.updatedGuest,
        });
        this.$root.$emit(
          "showCustomSuccess",
          `Персона ${this.updatedGuest.first_name} был успешно обновлен`
        );
        this.$emit("refreshData");
      } catch (result) {
        this.$root.$emit("showCustomError", result);
      } finally {
        this.resetState();
      }
    },
    async getGroups() {
      try {
        const response = await this.$axios.get("groups/short/", {
          params: {
            branch_id: this.updatedGuest.branch_id,
          },
        });
        this.groups = response.data;
      } catch (e) {
        console.log(e);
        this.$root.$emit("showDefaultError");
      }
    },
    async getDevices() {
      try {
        const response = await this.$axios.get("devices/short/");
        this.devices = response.data;
      } catch (e) {
        this.$root.$emit(
          "showCustomError",
          "Ошибка во время получения списка устройств"
        );
      }
    },
    async getDetails() {
      try {
        const response = await axios.get(`persons/${this.id}/details/`);
        this.updatedGuest = response.data;
        this.date = this.updatedGuest.expire_at.slice(0, 10);
        this.time = this.updatedGuest.expire_at.slice(11, 16);
        delete this.updatedGuest.ashyq_status;
        delete this.updatedGuest.attendances;
        delete this.updatedGuest.branch;
        delete this.updatedGuest.card_id;
        delete this.updatedGuest.created_at;
        delete this.updatedGuest.device;
        delete this.updatedGuest.face_photo;
        delete this.updatedGuest.group;
        delete this.updatedGuest.holiday_ends;
        delete this.updatedGuest.holiday_starts;
        delete this.updatedGuest.is_active;
        delete this.updatedGuest.organization;
        delete this.updatedGuest.supervisors;
        delete this.updatedGuest.updated_at;
      } catch (e) {
        this.$root.$emit("showCustomError", `Ошибка при открытие`);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.create-item {
  width: 240px;
  height: 41px;

  font-family: Gilroy-Medium, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #ffffff;
  text-transform: none !important;
}
.dialog-title {
  font-family: Gilroy-Medium, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 31px;
  text-align: center;
  color: #3474d3;
}
.preview {
  position: relative;
  height: 125px;
  width: 125px;

  .delete-btn {
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>
