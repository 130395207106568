<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn @click="getDetails" v-bind="attrs" v-on="on" icon>
        <v-icon color="success">mdi-eye-outline</v-icon>
      </v-btn>
    </template>
    <v-card class="rounded-lg">
      <v-card-title class="pb-0">
        <v-row align="center" justify="space-between">
          <v-col class="px-0" cols="auto"></v-col>
          <v-col class="px-0" cols="auto">
            <p class="dialog-title">Подробный просмотр</p>
          </v-col>
          <v-col class="px-0" cols="auto">
            <v-btn icon @click="dialog = false">
              <v-icon size="30" color="error">mdi-close-thick</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text style="padding: 15px 40px">
        <v-row>
          <v-col cols="6">
            <div class="input-wrapper">
              <label>ID расписания <span class="red--text">*</span> </label>
              <v-text-field
                disabled
                autocomplete="off"
                v-model="schedule.id"
                outlined
                dense
              />
            </div>
            <div class="input-wrapper">
              <label
                >ID Группы
                <span class="red--text">*</span>
              </label>
              <v-text-field
                disabled
                autocomplete="off"
                v-model="group.id"
                outlined
                dense
              />
            </div>
            <div class="input-wrapper">
              <label>Название группы <span class="red--text">*</span> </label>
              <v-text-field
                disabled
                v-model="group.title"
                outlined
                dense
              ></v-text-field>
            </div>
            <div class="input-wrapper">
              <label>День недели </label>
              <v-text-field
                disabled
                autocomplete="off"
                v-model="schedule.weekday"
                outlined
                dense
              />
            </div>
          </v-col>
          <v-col cols="6">
            <div class="input-wrapper my-3">
              <label class="ma-0">Время началы: </label>
              <p class="detail-text">
                {{ schedule.starts_at }}
              </p>
            </div>
            <div class="input-wrapper my-3">
              <label class="ma-0">Время конца: </label>
              <p class="detail-text">
                {{ schedule.ends_at }}
              </p>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "DetailSchedules",
  props: {
    schedule_id: {
      type: Number,
      required: true,
    },
    group_id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      group: {},
      schedule: {},
      dialog: false,
    };
  },
  methods: {
    async getDetails() {
      try {
        const response = await axios.get(
          `/groups/${this.group_id}/schedules/${this.schedule_id}/detail/`
        );
        this.schedule = response.data;
        this.group = response.data.group;
      } catch (e) {
        this.$root.$emit("showCustomError", `Ошибка при открытие`);
      }
    },
  },
};
</script>

<style scoped></style>
