<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="btn-text"
        v-bind="attrs"
        v-on="on"
        color="#2CBF56"
        style="text-transform: none"
        rounded
        >Создать табель
        <v-icon class="ml-2" size="20">mdi-plus-circle</v-icon>
      </v-btn>
    </template>
    <v-card class="rounded-xl" style="overflow: hidden">
      <v-row>
        <v-col>
          <v-spacer />
        </v-col>
        <v-card-title
          class="title_color"
          style="padding-top: 20px; margin-left: 10px"
        >
          Создать отчёт
        </v-card-title>
        <v-col
          style="
            display: flex;
            flex-direction: row-reverse;
            margin-right: 30px;
            margin-top: 10px;
          "
        >
          <v-btn @click="resetState" icon>
            <v-icon large>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-card-text style="padding: 15px 40px">
        <v-form v-model="valid" valid>
          <v-row justify="space-around">
            <v-col cols="auto">
              <v-menu
                v-model="menuFrom"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <label>Дата начала </label>
                  <div class="input-wrapper" style="width: 150px">
                    <v-text-field
                      v-model="params.date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                    ></v-text-field>
                  </div>
                </template>
                <v-date-picker
                  v-model="params.date"
                  type="month"
                  scrollable
                  @input="menuFrom = false"
                ></v-date-picker> </v-menu
            ></v-col>
          </v-row>
          <v-col>
            <v-btn
              style="text-transform: none"
              class="white--text"
              block
              @click="createTable"
              :disabled="!valid"
              color="#3474D3"
              >Сохранить</v-btn
            >
          </v-col>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "TableReports",
  data: () => {
    return {
      reports: {},
      dialog: false,
      valid: false,
      rules: {
        required: [(v) => !!v || "Поле обязательно для заполнения"],
      },
      menuFrom: false,
      menuTo: false,
      params: {
        date: new Date().toISOString().substr(0, 7),
      },
    };
  },
  methods: {
    resetState() {
      this.reports = {};
      this.dialog = false;
      console.log(this.params.date);
    },
    async createTable() {
      try {
        await this.$axios.post("reports/tables/create/", {
          year: this.params.date.substr(0, 4),
          month: this.params.date.substr(5, 6),
        });
        this.$root.$emit("showCustomSuccess", "Табель успешно создан!");
        this.$emit("refreshData");
      } catch (result) {
        this.$root.$emit("showCustomError", result);
      } finally {
        this.resetState();
      }
    },
  },
};
</script>

<style scoped>
.btn-text {
  width: 240px;
  height: 41px;

  font-family: Gilroy-Medium, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #ffffff;
  text-transform: none;
}
</style>
