<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        @click="timeNow"
        v-bind="attrs"
        v-on="on"
        class="create-item"
        color="#2CBF56"
        depressed
        rounded
      >
        Создать расписание
        <v-icon class="ml-2" size="20">mdi-plus-circle</v-icon>
      </v-btn>
    </template>
    <v-card class="rounded-lg">
      <v-card-title class="pb-0">
        <v-row align="center" justify="space-between">
          <v-col class="px-0" cols="auto"></v-col>
          <v-col class="px-0" cols="auto">
            <p class="dialog-title">Создание расписание</p>
          </v-col>
          <v-col class="px-0" cols="auto">
            <v-btn icon @click="resetState">
              <v-icon size="30" color="error">mdi-close-thick</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text style="padding: 15px 40px">
        <v-form v-model="valid">
          <div class="input-wrapper">
            <label for="group_id">Группа </label>
            <v-select
              v-model="schedules.group_id_list"
              :items="groups"
              :rules="rules.required"
              id="group_id"
              item-text="title"
              item-value="id"
              multiple
              outlined
              dense
            ></v-select>
          </div>
          <div class="input-wrapper">
            <label for="weekday">День недели </label>
            <v-select
              v-model="schedules.weekday"
              :items="weekday"
              :rules="rules.required"
              id="weekday"
              item-text="text"
              item-value="id"
              outlined
              dense
            ></v-select>
          </div>
          <v-row justify="space-around">
            <v-col cols="auto">
              <label>Время начала </label>
              <div class="input-wrapper" style="width: 150px">
                <v-text-field
                  v-model="schedules.starts_at"
                  prepend-icon="mdi-clock"
                  :rules="rules.required"
                  outlined
                ></v-text-field>
              </div>
            </v-col>
            <v-col cols="auto">
              <label>Время конца </label>
              <div class="input-wrapper" style="width: 150px">
                <v-text-field
                  v-model="schedules.ends_at"
                  prepend-icon="mdi-clock"
                  :rules="rules.required"
                  outlined
                ></v-text-field>
              </div>
            </v-col>
            <v-col cols="auto">
              <label>Выходной </label>
              <div class="input-wrapper pl-4" style="width: 150px">
                <v-checkbox
                  class="pl-2"
                  v-model="schedules.is_day_off"
                  hide-details
                />
              </div>
            </v-col>
          </v-row>
          <v-col>
            <v-btn
              style="text-transform: none"
              class="white--text"
              block
              @click="createSchedules"
              :disabled="!valid"
              color="#3474D3"
              >Сохранить</v-btn
            >
          </v-col>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CreateSchedules",
  props: {
    groups: {
      type: Array,
      required: true,
    },
  },
  data: () => {
    return {
      schedules: { is_day_off: false },
      dialog: false,
      valid: false,
      weekday: [
        {
          id: 0,
          text: "Понедельник",
        },
        {
          id: 1,
          text: "Вторник",
        },
        {
          id: 2,
          text: "Среда",
        },
        {
          id: 3,
          text: "Четверг",
        },
        {
          id: 4,
          text: "Пятница",
        },
        {
          id: 5,
          text: "Суббота",
        },
        {
          id: 6,
          text: "Воскресенье",
        },
      ],
      rules: {
        required: [
          (v) => {
            if (v || v === 0) {
              if (v.toString().length > 0) return true;
            }
            return "Поле обязательно для заполнения";
          },
        ],
      },
      menuFrom: false,
      menuTo: false,
      starts_at: null,
      ends_at: null,
    };
  },
  methods: {
    resetState() {
      this.schedules = {};
      this.dialog = false;
      this.starts_at = null;
      this.ends_at = null;
    },
    async createSchedules() {
      try {
        await this.$axios.post(`/groups/schedules/create/list/`, {
          ...this.schedules,
        });
        this.$root.$emit("showCustomSuccess", "Расписание успешно создано!");
        this.$emit("refreshData");
      } catch (result) {
        this.$root.$emit("showCustomError", result);
      } finally {
        this.resetState();
      }
    },
    timeNow() {
      this.starts_at = new Date()
        .toLocaleTimeString("en-US", {
          hour12: false,
        })
        .slice(0, 5);
      this.ends_at = new Date()
        .toLocaleTimeString("en-US", {
          hour12: false,
        })
        .slice(0, 5);
    },
  },
};
</script>

<style scoped lang="scss">
.create-item {
  width: 240px;
  height: 41px;

  font-family: Gilroy-Medium, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #ffffff;
  text-transform: none !important;
}
.dialog-title {
  font-family: Gilroy-Medium, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 31px;
  text-align: center;
  color: #3474d3;
}
.preview {
  position: relative;
  height: 125px;
  width: 125px;

  .delete-btn {
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>
