import Vue from "vue";
import VueRouter from "vue-router";

import Home from "@/views/Home";
import AuthorizedLayout from "@/layouts/AuthorizedLayout";
import Login from "../views/Login";
import NotAuthorizedLayout from "../layouts/NotAuthorizedLayout";
import MyProfile from "../views/MyProfile";
import Persons from "@/views/Persons";
import Branches from "../views/Branches";
import Devices from "@/views/Devices";
import Users from "@/views/Users";
import PageNotFound from "@/views/PageNotFound";
import Groups from "@/views/Groups";
import Roles from "@/views/Roles";
import Reports from "@/views/Reports";
import Attendance from "@/views/Attendance";
import Schedules from "@/views/Schedules";
import Guests from "@/views/Guests";
import Fiestas from "@/views/Fiestas";
import Settings from "@/views/Settings";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    component: NotAuthorizedLayout,
    children: [
      {
        path: "",
        name: "login",
        component: Login,
      },
    ],
  },
  {
    path: "/",
    component: AuthorizedLayout,
    children: [
      {
        path: "",
        name: "home",
        component: Home,
      },
      {
        path: "persons",
        name: "persons",
        component: Persons,
        props: {
          group: {
            type: String,
            default: "",
          },
        },
      },
      {
        path: "guests",
        name: "guests",
        component: Guests,
      },
      {
        path: "my-profile",
        name: "my-profile",
        component: MyProfile,
      },
      {
        path: "branches",
        name: "branches",
        component: Branches,
      },
      {
        path: "devices",
        name: "devices",
        component: Devices,
      },
      {
        path: "users",
        name: "users",
        component: Users,
      },
      {
        path: "groups",
        name: "groups",
        component: Groups,
      },
      {
        path: "roles",
        name: "roles",
        component: Roles,
      },
      {
        path: "reports",
        name: "reports",
        component: Reports,
      },
      {
        path: "attendance",
        name: "attendance",
        component: Attendance,
      },
      {
        path: "schedules",
        name: "schedules",
        component: Schedules,
      },
      {
        path: "fiestas",
        name: "fiestas",
        component: Fiestas,
      },
      {
        path: "Settings",
        name: "Settings",
        component: Settings,
      },
    ],
  },
  {
    path: "/:catchAll(.*)",
    component: PageNotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const access = localStorage.getItem("access");
  const refresh = localStorage.getItem("refresh");
  if (!access && !refresh) {
    if (to.name !== "login") next({ name: "login" });
  } else if (to.name === "login") {
    console.log("TO LOGIN");
    next({ name: "home" });
  }
  next();
});

export default router;
