<template>
  <v-dialog v-model="dialog" width="1200" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn @click="getDetails" v-bind="attrs" v-on="on" icon>
        <v-icon color="success">mdi-eye-outline</v-icon>
      </v-btn>
    </template>
    <v-card class="rounded-lg">
      <v-card-title class="pb-0">
        <v-row align="center" justify="space-between">
          <v-col class="px-0" cols="auto"></v-col>
          <v-col class="px-0" cols="auto">
            <p class="dialog-title">Подробный просмотр</p>
          </v-col>
          <v-col class="px-0" cols="auto">
            <v-btn icon @click="dialog = false">
              <v-icon size="30" color="error">mdi-close-thick</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text style="padding: 15px 40px">
        <v-row>
          <v-col cols="5">
            <div class="d-flex flex-row align-center justify-space-between">
              <div class="input-wrapper" style="width: 48%">
                <label>Имя </label>
                <v-text-field
                  disabled
                  autocomplete="off"
                  v-model="persons.first_name"
                  outlined
                  dense
                />
              </div>
              <div class="input-wrapper" style="width: 48%">
                <label>Фамалия </label>
                <v-text-field
                  disabled
                  autocomplete="off"
                  v-model="persons.last_name"
                  outlined
                  dense
                />
              </div>
            </div>
            <div class="input-wrapper">
              <label>Отчество </label>
              <v-text-field
                disabled
                autocomplete="off"
                v-model="persons.patronymic"
                outlined
                dense
              />
            </div>
            <div class="input-wrapper">
              <label>ИИН </label>
              <v-text-field
                disabled
                autocomplete="off"
                v-model="persons.iin"
                outlined
                dense
              />
            </div>
            <div class="input-wrapper">
              <label>Филиал </label>
              <v-text-field
                disabled
                autocomplete="off"
                v-model="persons.branch"
                outlined
                dense
              />
            </div>
            <div class="input-wrapper">
              <label>Группа </label>
              <v-text-field
                disabled
                autocomplete="off"
                v-model="persons.group"
                outlined
                dense
              />
            </div>
            <div class="input-wrapper">
              <label>Telegram ID </label>
              <v-text-field
                disabled
                autocomplete="off"
                v-model="persons.telegram_id"
                outlined
                dense
              />
            </div>
          </v-col>
          <v-col cols="4">
            <div class="input-wrapper my-3">
              <label class="ma-0">ID: </label>
              <p class="detail-text">{{ persons.id }}</p>
            </div>
            <div class="input-wrapper my-3">
              <label class="ma-0">Организация: </label>
              <p class="detail-text">{{ persons.organization }}</p>
            </div>
            <div class="input-wrapper my-3">
              <label class="ma-0">Дата создания: </label>
              <p class="detail-text">
                {{ new Date(persons.created_at).toLocaleDateString("ru-RU") }}
                {{ new Date(persons.created_at).toLocaleTimeString("ru-RU") }}
              </p>
            </div>
            <div class="input-wrapper my-3">
              <label class="ma-0">Дата обновления: </label>
              <p class="detail-text">
                {{ new Date(persons.updated_at).toLocaleDateString("ru-RU") }}
                {{ new Date(persons.updated_at).toLocaleTimeString("ru-RU") }}
              </p>
            </div>
            <div class="input-wrapper my-3">
              <label class="ma-0">Планшеты: </label>
              <p class="detail-text">{{ persons.device }}</p>
            </div>
            <div class="input-wrapper my-3">
              <label class="ma-0">ID карты: </label>
              <p class="detail-text">{{ persons.card_id }}</p>
            </div>
            <div class="input-wrapper my-3">
              <label class="ma-0">Активность: </label>
              <p class="detail-text">{{ persons.is_active ? "Да" : "Нет" }}</p>
            </div>
            <div class="input-wrapper my-3">
              <label class="ma-0">Создатель: </label>
              <p class="detail-text">{{ persons.created_by }}</p>
            </div>
          </v-col>
          <v-col cols="3">
            <div class="input-wrapper my-3" v-if="persons.face_photo">
              <img width="200" height="200" :src="persons.face_photo" />
            </div>
            <div class="input-wrapper my-3">
              <label class="ma-0">Запрещенное время: </label>
              <p class="detail-text">
                {{ persons.passtime }}
              </p>
            </div>
            <div class="input-wrapper my-3">
              <label class="ma-0">Срок истечения: </label>
              <p class="detail-text">
                {{ new Date(persons.expire_at).toLocaleDateString("ru-RU") }}
                {{ new Date(persons.expire_at).toLocaleTimeString("ru-RU") }}
              </p>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "DetailGuest",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      persons: {},
      dialog: false,
    };
  },
  methods: {
    async getDetails() {
      try {
        const response = await axios.get(`persons/${this.id}/details/`);
        this.persons = response.data;
      } catch (e) {
        this.$root.$emit("showCustomError", `Ошибка при открытие`);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.dialog-title {
  font-family: Gilroy-Medium, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 31px;
  text-align: center;
  color: #3474d3;
}
.preview {
  position: relative;
  height: 125px;
  width: 125px;

  .delete-btn {
    position: absolute;
    right: 0;
    top: 0;
  }
}
.detail-text {
  font-family: "Gilroy-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}
</style>

<!--<div class="input-wrapper" style="width: 58%">-->
<!--<label>Номер карты </label>-->
<!--<v-text-field-->
<!--    disabled-->
<!--    autocomplete="off"-->
<!--    v-model="persons.card_id"-->
<!--    outlined-->
<!--    dense-->
<!--/>-->
<!--</div>-->
<!--<div class="input-wrapper" style="width: 58%">-->
<!--<label>Планшет </label>-->
<!--<v-text-field-->
<!--    disabled-->
<!--    autocomplete="off"-->
<!--    v-model="persons.personss"-->
<!--    outlined-->
<!--    dense-->
<!--/>-->
<!--</div>-->
<!--<div class="input-wrapper" style="width: 58%">-->
<!--<label>Истекает </label>-->
<!--<v-text-field-->
<!--    disabled-->
<!--    autocomplete="off"-->
<!--    v-model="persons.expire_at"-->
<!--    outlined-->
<!--    dense-->
<!--/>-->
<!--</div>-->
<!--<div class="input-wrapper" style="width: 58%">-->
<!--<label>Время прохода? </label>-->
<!--<v-text-field-->
<!--    disabled-->
<!--    autocomplete="off"-->
<!--    v-model="persons.passtime"-->
<!--    outlined-->
<!--    dense-->
<!--/>-->
<!--</div>-->
