<template>
  <v-container fluid class="px-5">
    <v-row class="options" align="center">
      <v-col cols="auto" class="pl-4">
        <p class="title">Посещения</p>
      </v-col>
      <div style="width: 110px">
        <div class="input-wrapper">
          <v-select
            @input="getAttendance(1)"
            :items="pagination.limits"
            v-model="pagination.limit"
            hide-spin-buttons
            hide-selected
            hide-details
            outlined
            dense
          />
        </div>
      </div>
      <v-spacer />
    </v-row>
    <v-row class="options" align="center">
      <v-col cols="auto" class="pl-4">
        <p class="label">Филиал:</p>
      </v-col>
      <div style="width: 330px">
        <div class="input-wrapper">
          <v-select
            @input="getAttendance(1)"
            :items="branches"
            v-model="params.branch"
            item-text="title"
            item-value="id"
            hide-spin-buttons
            hide-selected
            hide-details
            outlined
            dense
            clearable
          />
        </div>
      </div>
      <v-col cols="auto" class="pl-4">
        <p class="label">Персона:</p>
      </v-col>
      <div style="width: 330px">
        <div class="input-wrapper">
          <v-autocomplete
            @input="getAttendance(1)"
            v-model="params.person_id"
            :items="persons"
            :search-input.sync="searchPerson"
            item-text="full_name"
            item-value="id"
            outlined
            deletable-chips
            small-chips
            dense
            hide-details
            clearable
            ref="select"
          >
          </v-autocomplete>
        </div>
      </div>
      <v-col cols="auto" class="pl-4">
        <p class="label">Отображать:</p>
      </v-col>
      <div style="width: 110px">
        <div class="input-wrapper">
          <v-select
            @input="getAttendance(1)"
            :items="types"
            v-model="type"
            item-text="title"
            item-value="value"
            hide-spin-buttons
            hide-details
            outlined
            dense
          />
        </div>
      </div>
      <v-col v-if="type === 'enter'" cols="auto" class="pl-4">
        <div class="d-flex flex-row align-center">
          <p class="label">Опоздал</p>
          <v-checkbox
            class="pl-2"
            @change="getAttendance(1)"
            v-model="params.isLate"
            hide-details
          />
        </div>
      </v-col>
      <v-col v-if="type === 'exit'" cols="auto" class="pl-4">
        <div class="d-flex flex-row align-center">
          <p class="label">Ушел раньше</p>
          <v-checkbox
            class="pl-2"
            @change="getAttendance(1)"
            v-model="params.isEscaped"
            hide-details
          />
        </div>
      </v-col>
      <v-col cols="auto" class="pl-4">
        <div class="d-flex flex-row align-center">
          <p class="label">Отсуствует</p>
          <AbsentPersons :list="this.branches" />
        </div>
      </v-col>
      <v-spacer />
    </v-row>
    <v-row class="options" align="center">
      <v-col cols="auto">
        <v-row align="center">
          <v-col cols="auto">
            <p>Период:</p>
          </v-col>
          <v-col cols="auto">
            <v-menu
              v-model="menuFrom"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <div class="input-wrapper" style="width: 160px">
                  <v-text-field
                    v-model="params.fromDate"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    hide-details
                  ></v-text-field>
                </div>
              </template>
              <v-date-picker
                v-model="params.fromDate"
                @change="getAttendance"
                :max="params.toDate"
                scrollable
                @input="menuFrom = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="auto">
            <p>-</p>
          </v-col>
          <v-col cols="auto">
            <v-menu
              v-model="menuTo"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <div class="input-wrapper" style="width: 160px">
                  <v-text-field
                    v-model="params.toDate"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    hide-details
                  ></v-text-field>
                </div>
              </template>
              <v-date-picker
                v-model="params.toDate"
                @change="getAttendance"
                :min="params.fromDate"
                :max="new Date().toISOString().substr(0, 10)"
                scrollable
                @input="menuTo = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-data-table
      class="elevation-1"
      :headers="headers"
      :items="items"
      :loading="loading"
      :search="search"
      :items-per-page="pagination.limit"
      loading-text="Пожалуйста подождите, данные загружаются..."
      hide-default-footer
    >
      <template v-slot:item.date="{ item }">
        {{ new Date(item.datetime).toLocaleDateString("ru-RU") }}
      </template>
      <template v-slot:item.time="{ item }">
        {{ new Date(item.datetime).toLocaleTimeString("ru-RU").slice(0, 5) }}
      </template>
      <template v-slot:item.fullName="{ item }">
        {{ item.attendance.person }}
      </template>
      <!--      <template v-slot:item.branch="{ item }">-->
      <!--        {{ item.attendance.branch }}-->
      <!--      </template>-->
      <template v-slot:item.group="{ item }">
        {{ item.attendance.group }}
      </template>
      <template v-slot:item.log>
        <span v-if="type === 'enter'" class="enter-log">Вход</span>
        <span v-else class="exit-log">Выход</span>
      </template>
      <template v-slot:item.method="{ item }">
        <span v-if="item.passing_type === 'FACE_IDENT'" class="passing-face">
          Биометрия
        </span>
        <span v-else-if="item.passing_type === 'CARD'" class="passing-card">
          Карта
        </span>
      </template>
    </v-data-table>
    <custom-pagination
      @updatePage="getAttendance($event)"
      :pagination="pagination"
    />
  </v-container>
</template>

<script>
import CustomPagination from "@/components/CustomPagination";
import AbsentPersons from "@/components/Attendance/AbsentPersons";
export default {
  name: "Attendance",
  components: { AbsentPersons, CustomPagination },
  data() {
    return {
      headers: [
        {
          text: "#",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: "Дата",
          align: "start",
          sortable: true,
          value: "date",
        },
        {
          text: "Время",
          align: "start",
          sortable: true,
          value: "time",
        },
        {
          text: "ФИО",
          align: "start",
          sortable: true,
          value: "fullName",
        },
        {
          text: "Статус",
          align: "start",
          sortable: true,
          value: "status",
        },
        // {
        //   text: "Филиал",
        //   align: "start",
        //   sortable: true,
        //   value: "branch",
        // },
        {
          text: "Группа",
          align: "start",
          sortable: true,
          value: "group",
        },
        // TODO: Add position when it's done on backend
        // {
        //   text: "Должность",
        //   align: "start",
        //   sortable: true,
        //   value: "position",
        // },
        {
          text: "Лог",
          align: "start",
          sortable: true,
          value: "log",
        },
        {
          text: "Способ",
          align: "start",
          sortable: true,
          value: "method",
        },
      ],
      pagination: {
        limit: 10,
        limits: [10, 50, 100],
        offset: 0,
        count: 0,
        page: 1,
        pages: 1,
      },
      params: {
        fromDate: new Date().toISOString().substr(0, 10),
        toDate: new Date(new Date().setDate(new Date().getDate() + 1))
          .toISOString()
          .substr(0, 10),
        person_id: null,
        branch: "",
        isLate: false,
        isEscaped: false,
      },
      menuFrom: false,
      menuTo: false,
      items: [],
      searchPerson: "",
      types: [
        {
          title: "Выход",
          value: "exit",
        },
        {
          title: "Вход",
          value: "enter",
        },
      ],
      type: "enter",
      branches: [],
      persons: [],
      loading: true,
      search: "",
    };
  },
  methods: {
    async getAttendance(page = 1) {
      if (this.type === "enter") this.params.isEscaped = false;
      else this.params.isLate = false;
      this.loading = true;
      this.items = [];
      this.pagination.page = page;
      this.pagination.offset =
        (this.pagination.page - 1) * this.pagination.limit;
      const url =
        this.type === "enter"
          ? "attendances/enter-logs/"
          : "attendances/exit-logs/";
      try {
        const response = await this.$axios.get(url, {
          params: {
            from_date: this.params.fromDate,
            to_date: this.params.toDate,
            limit: this.pagination.limit,
            offset: this.pagination.offset,
            is_late: this.params.isLate,
            is_escaped: this.params.isEscaped,
            branch_id: this.params.branch_id,
            person_id: this.params.person_id,
          },
        });
        this.items = response.data.results;
        this.loading = false;
        this.pagination.count = response.data.count;
        this.pagination.pages =
          Math.floor(this.pagination.count / this.pagination.limit) + 1;
      } catch (e) {
        this.loading = false;
        this.$root.$emit("showDefaultError");
      }
    },
    async getBranches() {
      try {
        const response = await this.$axios.get("organizations/branches/short/");
        response.data;
        this.branches = response.data;
      } catch (e) {
        this.$root.$emit("showDefaultError");
      }
    },
    async pause(seconds) {
      return await new Promise((resolve) => setTimeout(resolve, seconds));
    },
    async getPersons() {
      await this.pause(1000);
      try {
        const response = await this.$axios.get("persons/short/", {
          params: {
            organization_id: this.$store.state.user.organization.id,
          },
        });
        response.data;
        this.persons = response.data;
      } catch (e) {
        this.$root.$emit("showDefaultError");
      }
    },
  },
  created() {
    this.getAttendance();
    this.getBranches();
    this.getPersons();
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  margin-left: 20px;

  .options {
    display: flex;
    flex-direction: row;
    align-items: center;

    .custom-select {
      width: 100px !important;
      max-width: 100px !important;
    }

    .title {
      font-family: Gilroy-Medium, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 26px;
      line-height: 31px;
      color: #2c2c32;
    }

    .label {
      font-family: Gilroy-Medium, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 18px;
      color: #2c2c32;
    }
  }
}
.enter-log,
.exit-log,
.passing-face,
.passing-card {
  font-family: Gilroy-Medium, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
}
.enter-log {
  color: #2cbf56;
}
.exit-log {
  color: #ed564f;
}
.passing-face {
  color: #b19a13;
}
.passing-card {
  color: #3474d3;
}
</style>
