<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn @click="copyObject()" v-bind="attrs" v-on="on" icon>
        <v-icon color="blue">mdi-square-edit-outline</v-icon>
      </v-btn>
    </template>
    <v-card class="rounded-lg">
      <v-card-title class="pb-0">
        <v-row align="center" justify="space-between">
          <v-col class="px-0" cols="auto"></v-col>
          <v-col class="px-0" cols="auto">
            <p class="dialog-title">Редактирование расписание</p>
          </v-col>
          <v-col class="px-0" cols="auto">
            <v-btn icon @click="resetState">
              <v-icon size="30" color="error">mdi-close-thick</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text style="padding: 15px 40px">
        <v-form v-model="valid">
          <div class="input-wrapper">
            <label>День недели </label>
            <v-select
              v-model="schedules.weekday"
              :items="weekday"
              :rules="rules.required"
              item-text="text"
              item-value="id"
              outlined
              dense
            ></v-select>
          </div>
          <v-row justify="space-around">
            <v-col cols="auto">
              <label>Время начала </label>
              <div class="input-wrapper" style="width: 150px">
                <v-text-field
                  v-model="schedules.starts_at"
                  prepend-icon="mdi-clock"
                  :rules="rules.required"
                  outlined
                ></v-text-field>
              </div>
            </v-col>
            <v-col cols="auto">
              <label>Время конца </label>
              <div class="input-wrapper" style="width: 150px">
                <v-text-field
                  v-model="schedules.ends_at"
                  prepend-icon="mdi-clock"
                  :rules="rules.required"
                  outlined
                ></v-text-field>
              </div>
            </v-col>
            <v-col cols="auto">
              <label>Выходной </label>
              <div class="input-wrapper pl-4" style="width: 150px">
                <v-checkbox
                  class="pl-2"
                  v-model="schedules.is_day_off"
                  hide-details
                />
              </div>
            </v-col>
          </v-row>
          <v-col>
            <v-btn
              style="text-transform: none"
              class="white--text"
              block
              @click="updateSchedules(schedules)"
              :disabled="!valid"
              color="#3474D3"
              >Сохранить</v-btn
            >
          </v-col>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "UpdateSchedules",
  props: {
    groups: {
      type: Array,
      required: true,
    },
    schedulesDefault: {
      type: Object,
      required: true,
    },
    group_id: {
      type: [Number, String],
      required: true,
    },
  },
  data: () => {
    return {
      schedules: { is_day_off: false },
      dialog: false,
      valid: false,
      weekday: [
        {
          id: 0,
          text: "Понедельник",
        },
        {
          id: 1,
          text: "Вторник",
        },
        {
          id: 2,
          text: "Среда",
        },
        {
          id: 3,
          text: "Четверг",
        },
        {
          id: 4,
          text: "Пятница",
        },
        {
          id: 5,
          text: "Суббота",
        },
        {
          id: 6,
          text: "Воскресенье",
        },
      ],
      rules: {
        required: [
          (v) => {
            if (v || v === 0) {
              if (v.toString().length > 0) return true;
            }
            return "Поле обязательно для заполнения";
          },
        ],
      },
    };
  },
  methods: {
    copyObject() {
      Object.assign(this.schedules, this.schedulesDefault);
      this.schedules.group_id = this.groups.id;
      delete this.schedules.group;
    },
    resetState() {
      this.schedules = {};
      this.dialog = false;
      this.starts_at = null;
      this.ends_at = null;
    },
    async updateSchedules(schedule) {
      try {
        await this.$axios.post(
          `groups/${this.group_id}/schedules/${schedule.id}/update/`,
          {
            ...this.schedules,
          }
        );
        this.$root.$emit("showCustomSuccess", "Расписание успешно обновлено!");
        this.$emit("refreshData");
      } catch (result) {
        this.$root.$emit("showCustomError", result);
      } finally {
        this.resetState();
      }
    },
  },
};
</script>

<style scoped></style>
