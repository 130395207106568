<template>
  <v-dialog v-model="dialog" width="800" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn @click="getDetails" v-bind="attrs" v-on="on" icon>
        <v-icon color="success">mdi-eye-outline</v-icon>
      </v-btn>
    </template>
    <v-card class="rounded-lg">
      <v-card-title class="pb-0">
        <v-row align="center" justify="space-between">
          <v-col class="px-0" cols="auto"></v-col>
          <v-col class="px-0" cols="auto">
            <p class="dialog-title">Подробный просмотр</p>
          </v-col>
          <v-col class="px-0" cols="auto">
            <v-btn icon @click="dialog = false">
              <v-icon size="30" color="error">mdi-close-thick</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text style="padding: 15px 40px">
        <v-row>
          <v-col cols="6">
            <div class="input-wrapper">
              <label>Название группы<span class="red--text">*</span> </label>
              <v-text-field disabled v-model="groups.title" outlined dense />
            </div>
            <div class="input-wrapper">
              <label>Филиал <span class="red--text">*</span> </label>
              <v-text-field disabled v-model="branch.title" outlined dense />
            </div>
          </v-col>
          <v-col cols="6">
            <div class="input-wrapper my-3">
              <label class="ma-0">ID: </label>
              <p class="detail-text">{{ groups.id }}</p>
            </div>
            <div class="input-wrapper my-3">
              <label class="ma-0">Организация: </label>
              <p class="detail-text">{{ organization.title }}</p>
            </div>
            <!--            <div class="input-wrapper my-3">-->
            <!--              <label class="ma-0">Город: </label>-->
            <!--              <p class="detail-text">{{ city.name }}</p>-->
            <!--            </div>-->
            <div class="input-wrapper my-3">
              <label class="ma-0">Улица: </label>
              <p class="detail-text">{{ branch.street }}</p>
            </div>
            <div class="input-wrapper my-3">
              <label class="ma-0">Номер здания: </label>
              <p class="detail-text">{{ branch.building_number }}</p>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "DetailGroups",
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      groups: {},
      dialog: false,
      organization: {},
      branch: {},
      city: {},
    };
  },
  methods: {
    async getDetails() {
      try {
        const response = await axios.get(`groups/${this.id}/detail/`);
        this.groups = response.data;
        this.organization = response.data.organization;
        this.branch = response.data.branch;
      } catch (e) {
        this.$root.$emit("showCustomError", `Ошибка при открытие`);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.dialog-title {
  font-family: Gilroy-Medium, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 31px;
  text-align: center;
  color: #3474d3;
}
.preview {
  position: relative;
  height: 125px;
  width: 125px;

  .delete-btn {
    position: absolute;
    right: 0;
    top: 0;
  }
}
.detail-text {
  font-family: "Gilroy-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}
</style>
