<template>
  <v-dialog v-model="dialog" width="1000" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn @click="getDetails" v-bind="attrs" v-on="on" icon>
        <v-icon color="success">mdi-eye-outline</v-icon>
      </v-btn>
    </template>
    <v-card class="rounded-lg">
      <v-card-title class="pb-0">
        <v-row align="center" justify="space-between">
          <v-col class="px-0" cols="auto"></v-col>
          <v-col class="px-0" cols="auto">
            <p class="dialog-title">Подробный просмотр</p>
          </v-col>
          <v-col class="px-0" cols="auto">
            <v-btn icon @click="dialog = false">
              <v-icon size="30" color="error">mdi-close-thick</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text style="padding: 15px 40px">
        <v-row>
          <v-col cols="6">
            <div class="input-wrapper">
              <label>Логин </label>
              <v-text-field
                disabled
                autocomplete="off"
                v-model="users.username"
                outlined
                dense
              />
            </div>
            <div class="input-wrapper">
              <label>Электронная почта </label>
              <v-text-field
                disabled
                autocomplete="off"
                v-model="users.email"
                outlined
                dense
              />
            </div>
            <div class="input-wrapper">
              <label>Номер телефона </label>
              <v-text-field
                disabled
                autocomplete="off"
                v-model="users.phone"
                outlined
                dense
              />
            </div>
            <div class="input-wrapper" v-if="branch">
              <label>Филиал </label>
              <v-text-field
                disabled
                autocomplete="off"
                v-model="users.branch"
                outlined
                dense
              />
            </div>
            <div class="input-wrapper">
              <label>ИИН </label>
              <v-text-field
                disabled
                autocomplete="off"
                v-model="users.iin"
                outlined
                dense
              />
            </div>
          </v-col>
          <v-col cols="6">
            <!--            <div-->
            <!--              class="input-wrapper d-flex flex-row align-center justify-start my-3"-->
            <!--            >-->
            <!--              <label class="detail-label ma-0">Имя: </label>-->
            <!--              <p class="detail-text">{{ users.first_name }}</p>-->
            <!--            </div>-->
            <!--            <div-->
            <!--              class="input-wrapper d-flex flex-row align-center justify-start my-3"-->
            <!--            >-->
            <!--              <label class="detail-label ma-0">Фамилия: </label>-->
            <!--              <p class="detail-text">{{ users.last_name }}</p>-->
            <!--            </div>-->
            <div
              class="input-wrapper d-flex flex-row align-center justify-start my-3"
            >
              <label class="detail-label ma-0">Администратор: </label>
              <p class="detail-text">
                {{ users.is_superuser ? "Да" : "Нет" }}
              </p>
            </div>
            <div
              class="input-wrapper d-flex flex-row align-center justify-start my-3"
            >
              <label class="detail-label ma-0">Персона: </label>
              <p class="detail-text">
                {{ users.is_staff ? "Да" : "Нет" }}
              </p>
            </div>
            <div
              class="input-wrapper d-flex flex-row align-center justify-start my-3"
            >
              <label class="detail-label ma-0">Активный: </label>
              <p class="detail-text">
                {{ users.is_active ? "Да" : "Нет" }}
              </p>
            </div>
            <div
              class="input-wrapper d-flex flex-row align-center justify-start my-3"
            >
              <label class="detail-label ma-0">Telegram ID: </label>
              <p class="detail-text">{{ users.telegram_id }}</p>
            </div>
            <div
              class="input-wrapper d-flex flex-row align-center justify-start my-3"
            >
              <label class="detail-label ma-0">Филиал: </label>
              <p class="detail-text">{{ users.branch }}</p>
            </div>
            <!--            <div class="input-wrapper d-flex flex-row align-center justify-start my-3">-->
            <!--              <label class="detail-label ma-0">Филиал: </label>-->
            <!--              <p class="detail-text">{{ branch.title }}</p>-->
            <!--            </div>-->
            <div
              class="input-wrapper d-flex flex-row align-center justify-start my-3"
            >
              <label class="detail-label ma-0">Роль: </label>
              <p class="detail-text" v-if="role">{{ role }}</p>
            </div>
            <!--            <div-->
            <!--              class="input-wrapper d-flex flex-row align-center justify-start my-3"-->
            <!--            >-->
            <!--              <label class="detail-label ma-0">Привилегии: </label>-->
            <!--              &lt;!&ndash;              <p class="detail-text">{{ users.privileges }}</p>&ndash;&gt;-->
            <!--              <v-list width="100%" class="transparent" dense>-->
            <!--                <v-list-group no-action class="transparent">-->
            <!--                  <template v-slot:activator>-->
            <!--                    <v-list-item-title>Права пользователей</v-list-item-title>-->
            <!--                  </template>-->
            <!--                  <v-list-item-->
            <!--                    class="detail-text"-->
            <!--                    v-for="privilege in users.privileges"-->
            <!--                    :key="privilege.id"-->
            <!--                  >-->
            <!--                    {{ privilege.name }}-->
            <!--                  </v-list-item>-->
            <!--                </v-list-group>-->
            <!--              </v-list>-->
            <!--            </div>-->
            <div
              class="input-wrapper d-flex flex-row align-center justify-start my-3"
            >
              <label class="detail-label ma-0">Дата создания: </label>
              <p class="detail-text">
                {{ new Date(users.created_at).toLocaleDateString("ru-RU") }}
                {{ new Date(users.created_at).toLocaleTimeString("ru-RU") }}
              </p>
            </div>
            <div
              class="input-wrapper d-flex flex-row align-center justify-start my-3"
            >
              <label class="detail-label ma-0">Дата обновления: </label>
              <p class="detail-text">
                {{ new Date(users.updated_at).toLocaleDateString("ru-RU") }}
                {{ new Date(users.updated_at).toLocaleTimeString("ru-RU") }}
              </p>
            </div>
            <div
              class="input-wrapper d-flex flex-row align-center justify-start my-3"
            >
              <label class="detail-label ma-0">Дата последнего входа: </label>
              <p class="detail-text">
                {{ new Date(users.last_login).toLocaleDateString("ru-RU") }}
                {{ new Date(users.last_login).toLocaleTimeString("ru-RU") }}
              </p>
            </div>
            <div
              class="input-wrapper d-flex flex-row align-center justify-start my-3"
            >
              <label class="detail-label ma-0">Дата добавления: </label>
              <p class="detail-text">
                {{ new Date(users.date_joined).toLocaleDateString("ru-RU") }}
                {{ new Date(users.date_joined).toLocaleTimeString("ru-RU") }}
              </p>
            </div>
            <!--            <div-->
            <!--              class="input-wrapper d-flex flex-row align-center justify-start mb-3"-->
            <!--            >-->
            <!--              <label class="detail-label ma-0">: </label>-->
            <!--              <p class="detail-text">{{ users.id }}</p>-->
            <!--            </div>-->
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "DetailUsers",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      users: {},
      dialog: false,
      role: {},
      branch: {},
    };
  },
  methods: {
    async getDetails() {
      try {
        const response = await axios.get(`users/${this.id}/detail/`);
        this.users = response.data;
        this.role = response.data.role;
        this.branch = response.data.branch;
      } catch (e) {
        this.$root.$emit("showCustomError", `Ошибка при открытие`);
      }
    },
  },
  computed: {
    checkRole() {
      return this.role ? this.role.name : "";
    },
    checkBranch() {
      return this.branch.title ? this.branch.title : "";
    },
  },
};
</script>

<style scoped lang="scss">
.dialog-title {
  font-family: Gilroy-Medium, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 31px;
  text-align: center;
  color: #3474d3;
}
.preview {
  position: relative;
  height: 125px;
  width: 125px;

  .delete-btn {
    position: absolute;
    right: 0;
    top: 0;
  }
}
.detail-label {
  width: 150px;
  font-family: "Gilroy-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
}
.detail-text {
  margin-left: 10px !important;
  font-family: "Gilroy-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
}
</style>
