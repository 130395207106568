<template>
  <v-container fluid>
    <v-row class="options" align="center">
      <v-col cols="auto" class="pl-4">
        <p class="title">Гости</p>
      </v-col>
      <div style="width: 110px">
        <v-col cols="12">
          <div class="input-wrapper">
            <v-select
              @input="getGuests(1)"
              :items="pagination.limits"
              v-model="pagination.limit"
              hide-spin-buttons
              hide-selected
              hide-details
              outlined
            />
          </div>
        </v-col>
      </div>
      <v-spacer />
      <v-col cols="5">
        <v-text-field
          @input="getGuests(1)"
          v-model="search"
          background-color="#ffffff"
          prepend-inner-icon="mdi-magnify"
          class="rounded-xl"
          hide-spin-buttons
          hide-details
          outlined
          dense
        />
      </v-col>
      <v-col cols="auto">
        <create-guest
          v-if="canUser('person-c')"
          @refreshData="getGuests(pagination.page)"
          :branches="branches"
        />
      </v-col>
    </v-row>
    <v-data-table
      class="elevation-1"
      :headers="headers"
      :items="items"
      :items-per-page="pagination.limit"
      :loading="loading"
      loading-text="Пожалуйста подождите, данные загружаются..."
      hide-default-footer
    >
      <template v-slot:item.full_name="{ item }">
        {{ item.last_name }} {{ item.first_name }} {{ item.patronymic }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <detail-guest :id="item.id" />
        <update-guest-photo
          v-if="canUser('person-u')"
          @refreshData="getGuests(pagination.page)"
          :guest="item"
        />
        <update-guest
          v-if="canUser('person-u')"
          @refreshData="getGuests(pagination.page)"
          :guest="item"
          :id="item.id"
          :branches="branches"
        />
        <delete-item
          v-if="canUser('person-d')"
          :delete-item="deleteItem(item)"
          @deleteGuest="deleteGuest(item)"
        />
      </template>
    </v-data-table>
    <custom-pagination
      @updatePage="getGuests($event)"
      :pagination="pagination"
    />
  </v-container>
</template>

<script>
import CustomPagination from "@/components/CustomPagination";
import DeleteItem from "@/components/DeleteItem";
import CreateGuest from "@/components/Guests/CreateGuest";
import DetailGuest from "@/components/Guests/DetailGuest";
import UpdateGuest from "@/components/Guests/UpdateGuest";
import UpdateGuestPhoto from "@/components/Guests/UpdateGuestPhoto";
export default {
  name: "Guests",
  components: {
    UpdateGuestPhoto,
    UpdateGuest,
    DetailGuest,
    CreateGuest,
    DeleteItem,
    CustomPagination,
  },
  data() {
    return {
      loading: true,
      headers: [
        {
          text: "Группа",
          align: "start",
          sortable: true,
          value: "group.title",
        },
        {
          text: "ФИО",
          align: "start",
          sortable: true,
          value: "full_name",
        },
        {
          text: "ИИН",
          align: "start",
          sortable: true,
          value: "iin",
        },
        {
          text: "Организация",
          align: "start",
          sortable: true,
          value: "organization.title",
        },
        {
          text: "Филиал",
          align: "start",
          sortable: true,
          value: "branch.title",
        },
        {
          text: "Действия",
          align: "center",
          sortable: true,
          value: "actions",
        },
      ],
      pagination: {
        limit: 10,
        limits: [10, 50, 100],
        offset: 0,
        count: 0,
        page: 1,
        pages: 1,
      },
      items: [],
      branches: [],
      search: "",
      cancelToken: null,
    };
  },
  methods: {
    async getGuests(page = 1) {
      if (this.cancelToken != null) this.cancelToken.cancel();
      this.cancelToken = this.$axios.cancelToken.source();
      this.loading = true;
      this.items = [];
      this.pagination.page = page;
      this.pagination.offset =
        (this.pagination.page - 1) * this.pagination.limit;
      try {
        const response = await this.$axios.get("persons/", {
          cancelToken: this.cancelToken.token,
          params: {
            limit: this.pagination.limit,
            offset: this.pagination.offset,
            group_id: this.group_id,
            full_name: this.search,
            is_guest: true,
          },
        });
        this.items = response.data.results;
        this.loading = false;
        this.pagination.count = response.data.count;
        this.pagination.pages =
          Math.floor(this.pagination.count / this.pagination.limit) + 1;
      } catch (e) {
        if (e.status) {
          this.loading = false;
          this.$root.$emit("showDefaultError");
        }
      }
    },
    async getBranches() {
      try {
        const response = await this.$axios.get("organizations/branches/short/");
        response.data;
        this.branches = response.data;
      } catch (e) {
        this.$root.$emit("showDefaultError");
      }
    },
    async deleteGuest(guest) {
      try {
        await this.$axios.post(`persons/${guest.id}/delete/`);
        await this.getGuests(this.pagination.page);
      } catch (e) {
        this.$root.$emit("showDefaultError");
      }
    },
    deleteItem(item) {
      return {
        name: item.first_name,
        action: "deleteGuest",
      };
    },
  },
  created() {
    this.getGuests();
    this.getBranches();
  },
};
</script>

<style scoped></style>
