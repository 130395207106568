<template>
  <v-container fluid>
    <v-card
      class="rounded-xl d-flex flex-column"
      style="margin-left: 40px; padding-top: 20px; margin-right: 40px"
    >
      <v-card-title style="margin-left: 40px; padding-top: 20px; width: 100%">
        <v-row justify="space-between" align="center">
          <v-col cols="auto title_color header_text"
            >Настройки организации</v-col
          >
        </v-row>
      </v-card-title>
      <v-card-subtitle
        style="margin-left: 40px; padding-top: 30px; width: 100%"
      >
        <v-row justify="space-between" align="center">
          <v-col
            cols="auto title_color header_text"
            class="header-text-settings"
            >ASHYQ</v-col
          >
        </v-row>
      </v-card-subtitle>
      <v-card-text style="padding: 1px 50px">
        <p class="ml-1">Укажите разрешенные статусы ASHYQ</p>
        <v-simple-table style="width: 350px" class="ma-0 pa-0" dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Статус</th>
                <th class="text-left">Действие</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(i, index) in ashyq" :key="index">
                <td v-if="index === 'GREEN'" class="light-green lighten-3">
                  <p>{{ index }}</p>
                </td>
                <td v-if="index === 'RED'" class="red lighten-3">
                  <p>{{ index }}</p>
                </td>
                <td v-if="index === 'YELLOW'" class="yellow lighten-3">
                  <p>{{ index }}</p>
                </td>
                <td v-if="index === 'BLUE'" class="blue lighten-3">
                  <p>{{ index }}</p>
                </td>
                <td v-if="index === 'GREEN'" class="light-green lighten-3">
                  <v-switch class="mt-5" v-model="ashyq[index]"></v-switch>
                </td>
                <td v-if="index === 'RED'" class="red lighten-3">
                  <v-switch class="mt-5" v-model="ashyq[index]"></v-switch>
                </td>
                <td v-if="index === 'BLUE'" class="blue lighten-3">
                  <v-switch class="mt-5" v-model="ashyq[index]"></v-switch>
                </td>
                <td v-if="index === 'YELLOW'" class="yellow lighten-3">
                  <v-switch class="mt-5" v-model="ashyq[index]"></v-switch>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <p class="ml-1">Внимание! Изменения вступят в силу после 24:00</p>
        <v-btn
          style="
            margin-bottom: 50px;
            margin-left: 130px;
            margin-top: 10px;
            color: #ffffff;
            text-transform: none;
            background: #3474d3;
          "
          @click="
            updateAshyq();
            loader = 'loading';
          "
          :loading="loading"
          :disabled="loading"
          >OK</v-btn
        >
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Settings",
  props: {
    bgColor: {
      type: String,
      default: "#0099CC",
    },
  },
  data: () => {
    return {
      ashyq: [],
      array: [],
      default: [],
      loader: null,
      loading: false,
    };
  },
  methods: {
    async getAshyq() {
      const response = await this.$axios.get("ashyq/detail/");
      delete response.data.id;
      this.ashyq = response.data;
    },
    async updateAshyq() {
      try {
        await this.$axios.post("ashyq/update/", this.ashyq);
        this.$root.$emit(
          "showCustomSuccess",
          `Параметры входа успешно измененны`
        );
      } catch (e) {
        console.log(e);
        this.$root.$emit(
          "showCustomError",
          "Ошибка при изменение параматеров входа"
        );
      } finally {
        await this.getAshyq();
      }
    },
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 2000);

      this.loader = null;
    },
  },
  created() {
    this.getAshyq();
  },
};
</script>

<style scoped>
.header-text-settings {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 31px;
}
</style>
