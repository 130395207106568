<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        class="create-item"
        color="#2CBF56"
        depressed
        rounded
      >
        Создать праздник
        <v-icon class="ml-2" size="20">mdi-plus-circle</v-icon>
      </v-btn>
    </template>
    <v-card class="rounded-lg">
      <v-card-title class="pb-0">
        <v-row align="center" justify="space-between">
          <v-col class="px-0" cols="auto"></v-col>
          <v-col class="px-0" cols="auto">
            <p class="dialog-title">Создать праздник</p>
          </v-col>
          <v-col class="px-0" cols="auto">
            <v-btn icon @click="resetState">
              <v-icon size="30" color="error">mdi-close-thick</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text style="padding: 15px 40px">
        <v-form v-model="valid" valid>
          <div class="input-wrapper">
            <label for="roles.name">Название праздника </label>
            <v-text-field
              autocomplete="off"
              placeholder="Введите название праздника "
              v-model="fiesta.title"
              id="roles.name"
              outlined
              dense
              :rules="rules.required"
            />
          </div>
          <div class="input-wrapper">
            <label for="devices">праздника</label>
            <v-row class="ma-0">
              <v-col cols="11" class="ma-0 pa-0">
                <v-autocomplete
                  placeholder="Выберите филиал"
                  v-model="fiesta.branch_ids"
                  :items="branches"
                  :rules="rules.required"
                  :search-input.sync="searchDevice"
                  item-text="title"
                  item-value="id"
                  id="devices"
                  multiple
                  outlined
                  deletable-chips
                  small-chips
                  dense
                  ref="select"
                >
                  <template #append-item>
                    <div class="append">
                      <v-btn @click="$refs.select.blur()" color="primary"
                        >OK</v-btn
                      >
                    </div>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-btn
                @click="selectAll"
                class="mt-2"
                icon
                style="padding-bottom: 10px"
              >
                <v-icon>mdi-checkbox-multiple-marked</v-icon>
              </v-btn>
            </v-row>
          </div>
          <v-row justify="space-around">
            <v-col cols="auto">
              <v-menu
                v-model="menuFrom"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <label>Дата начала </label>
                  <div class="input-wrapper" style="width: 150px">
                    <v-text-field
                      v-model="fiesta.date_from"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                    ></v-text-field>
                  </div>
                </template>
                <v-date-picker
                  v-model="fiesta.date_from"
                  scrollable
                  @input="menuFrom = false"
                ></v-date-picker> </v-menu
            ></v-col>
            <v-col cols="auto">
              <v-menu
                v-model="menuTo"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <label>Дата завершения </label>
                  <div class="input-wrapper" style="width: 150px">
                    <v-text-field
                      v-model="fiesta.date_to"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                    ></v-text-field>
                  </div>
                </template>
                <v-date-picker
                  v-model="fiesta.date_to"
                  scrollable
                  @input="menuTo = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-btn
            @click="createFiesta"
            :disabled="!valid"
            :loading="loading"
            class="save-btn"
            depressed
            >Сохранить</v-btn
          >
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CreateFiesta",
  props: {
    branches: {
      type: Array,
      required: true,
    },
  },
  data: () => {
    return {
      fiesta: {
        branch_ids: [],
      },
      menuFrom: false,
      menuTo: false,
      dialog: false,
      valid: false,
      loading: false,
      searchDevice: "",
      rules: {
        required: [(v) => !!v || "Поле обязательно для заполнения"],
      },
      menu: false,
    };
  },
  methods: {
    resetState() {
      this.fiesta = {
        branch_ids: [],
      };
      this.dialog = false;
      this.loading = false;
    },
    async createFiesta() {
      this.loading = true;
      try {
        await this.$axios.post("groups/fiestas/create/", this.fiesta);
        this.$root.$emit("showCustomSuccess", "Праздник успешно создан!");
        this.$emit("refreshData");
      } catch (result) {
        this.$root.$emit("showCustomError", result);
      } finally {
        this.resetState();
      }
    },
    selectAll() {
      if (this.fiesta.branch_ids.length < this.branches.length) {
        let branch = this.branches.map((i) => i.id);
        this.$set(this.fiesta, "branch_ids", branch);
      } else {
        this.$set(this.fiesta, "branch_ids", []);
      }
    },
  },
};
</script>

<style scoped>
.create-item {
  width: 240px;
  height: 41px;

  font-family: Gilroy-Medium, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #ffffff;
  text-transform: none !important;
}
.dialog-title {
  font-family: Gilroy-Medium, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 31px;
  text-align: center;
  color: #3474d3;
}
.append {
  position: sticky;
  bottom: 8px;
  width: 100%;
  display: flex;
  justify-content: center;
  background: white;
}
</style>
