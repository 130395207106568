<template>
  <v-container fluid class="px-5">
    <v-row class="options" align="center">
      <v-col cols="auto" class="pl-7">
        <p class="title">Группы</p>
      </v-col>
      <div style="width: 110px">
        <v-col cols="12">
          <div class="input-wrapper">
            <v-select
              @input="getGroups(1)"
              :items="pagination.limits"
              v-model="pagination.limit"
              hide-spin-buttons
              hide-selected
              hide-details
              outlined
            />
          </div>
        </v-col>
      </div>
      <v-spacer />
      <v-col cols="auto">
        <CreateGroups
          v-if="canUser('group-c')"
          @refreshData="getGroups"
          :branch="branch"
        />
      </v-col>
    </v-row>
    <v-data-table
      class="elevation-1"
      :headers="headers"
      :items="groups"
      :items-per-page="pagination.limit"
      :loading="loading"
      :search="search"
      loading-text="Пожалуйста подождите, данные загружаются..."
      hide-default-footer
    >
      <template v-slot:item.branch="{ item }">
        {{ item.branch ? item.branch.title : "" }}
      </template>
      <template v-slot:item.persons="{ item }">
        <router-link :to="{ name: 'persons', params: { group: item.id } }"
          >К списку персон</router-link
        >
      </template>
      <template v-slot:item.actions="{ item }">
        <DetailGroups :id="item.id" />
        <UpdateGroups
          v-if="canUser('group-u')"
          @refreshData="getGroups"
          :groups="groups"
          :groupsDefault="item"
          :branch="branch"
        />
        <delete-item
          v-if="canUser('group-d')"
          :delete-item="deleteItem(item)"
          @deleteDevice="deleteGroups(item)"
        />
      </template>
    </v-data-table>
    <custom-pagination
      @updatePage="getGroups($event)"
      :pagination="pagination"
    />
  </v-container>
</template>

<script>
import DeleteItem from "@/components/DeleteItem";
import CustomPagination from "@/components/CustomPagination";
import CreateGroups from "@/components/Groups/CreateGroups";
import UpdateGroups from "@/components/Groups/UpdateGroups";
import DetailGroups from "@/components/Groups/DetailGroups";

export default {
  name: "Groups",
  components: {
    DetailGroups,
    UpdateGroups,
    DeleteItem,
    CreateGroups,
    CustomPagination,
  },

  data: () => {
    return {
      loading: true,
      groups: [],
      branch: [],
      headers: [
        {
          text: "Группа",
          align: "start",
          sortable: true,
          value: "title",
        },
        {
          text: "Организация",
          align: "start",
          sortable: true,
          value: "organization.title",
        },
        {
          text: "Филиал",
          align: "start",
          sortable: false,
          value: "branch",
        },
        {
          text: "Город",
          align: "start",
          sortable: false,
          value: "branch.city.name",
        },
        {
          text: "Персоны",
          align: "start",
          sortable: false,
          value: "persons",
        },
        {
          text: "Действие",
          align: "center",
          sortable: false,
          value: "actions",
        },
      ],
      pagination: {
        limit: 10,
        limits: [10, 50, 100],
        offset: 0,
        count: 0,
        page: 1,
        pages: 1,
      },
      search: "",
    };
  },
  methods: {
    async getGroups(page = 1) {
      this.loading = true;
      this.groups = [];
      this.pagination.page = page;
      this.pagination.offset =
        (this.pagination.page - 1) * this.pagination.limit;
      try {
        const response = await this.$axios.get("groups/", {
          params: {
            limit: this.pagination.limit,
            offset: this.pagination.offset,
          },
        });
        this.groups = response.data.results;
        this.loading = false;
        this.pagination.count = response.data.count;
        this.pagination.pages =
          Math.floor(this.pagination.count / this.pagination.limit) + 1;
      } catch (e) {
        this.loading = false;
        this.$root.$emit("showDefaultError");
      }
    },

    async getBranch() {
      try {
        const response = await this.$axios.get("organizations/branches/short/");
        this.branch = response.data;
      } catch (e) {
        this.$root.$emit("showCustomError", `Ошибка при получение филиалов`);
      }
    },
    async deleteGroups(groups) {
      try {
        await this.$axios.post(`groups/${groups.id}/delete/`);
        this.$root.$emit("showCustomSuccess", "Группа успешно удалена!");
        await this.getGroups(this.pagination.page);
      } catch (e) {
        this.$root.$emit(
          "showCustomError",
          `Ошибка во время удаления группы ${groups.id}`
        );
      }
    },
    deleteItem(item) {
      return {
        name: item.title,
        action: "deleteDevice",
      };
    },
  },
  mounted() {
    this.getGroups();
    this.getBranch();
  },
};
</script>

<style scoped></style>
