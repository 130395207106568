<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        class="create-item"
        color="#2CBF56"
        depressed
        rounded
      >
        Изменить пароль
      </v-btn>
    </template>
    <v-card class="rounded-lg" style="overflow: hidden">
      <v-card-title class="pb-0">
        <v-row align="center" justify="space-between">
          <v-col class="px-0" cols="auto"></v-col>
          <v-col class="px-0" cols="auto">
            <p class="dialog-title">Изменение пароля</p>
          </v-col>
          <v-col class="px-0" cols="auto">
            <v-btn icon @click="resetState">
              <v-icon size="30" color="error">mdi-close-thick</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text style="padding: 15px 40px">
        <v-form v-model="valid" valid>
          <div class="input-wrapper">
            <label for="show1">Старый пароль </label>
            <v-text-field
              :rules="rules.password"
              :type="show1 ? 'text' : 'password'"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              id="show1"
              v-model="passwords.old_password"
              hint="Поле обязательно для заполнения"
              @click:append="show1 = !show1"
              autocomplete="off"
              persistent-hint
              outlined
            ></v-text-field>
          </div>
          <div class="input-wrapper">
            <label for="show2">Новый пароль </label>
            <v-text-field
              :rules="rules.password"
              :type="show2 ? 'text' : 'password'"
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              id="show2"
              v-model="passwords.new_password"
              hint="Поле обязательно для заполнения"
              @click:append="show2 = !show2"
              autocomplete="off"
              persistent-hint
              outlined
            ></v-text-field>
          </div>
          <div class="input-wrapper">
            <label for="show3">Повторите новый пароль </label>
            <v-text-field
              :rules="rules.password"
              :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show3 ? 'text' : 'password'"
              id="show3"
              v-model="new_password2"
              hint="Поле обязательно для заполнения"
              @click:append="show3 = !show3"
              autocomplete="off"
              persistent-hint
              outlined
            ></v-text-field>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-space-between">
        <v-btn
          @click="UpdatePassword"
          :disabled="!valid"
          class="save-btn"
          depressed
          >Сохранить</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ProfileChangePassword",
  data: () => {
    return {
      passwords: {
        old_password: "",
        new_password: "",
      },
      new_password2: "",
      show1: false,
      show2: false,
      show3: false,
      dialog: false,
      valid: false,
      rules: {
        password: [
          (v) => {
            if (v) {
              return (
                v.length >= 8 || "Длина пароля не может быть меньше 8 символов"
              );
            }
            return "Поле обязательно для заполнения";
          },
        ],
      },
    };
  },
  methods: {
    resetState() {
      this.passwords = {};
      this.new_password2 = "";
      this.dialog = false;
    },

    async UpdatePassword() {
      if (this.passwords.new_password === this.new_password2) {
        try {
          await this.$axios.post(
            "users/myprofile/change-password/",
            this.passwords
          );
          this.$root.$emit("showCustomSuccess", "Пароль был изменён!");
        } catch (e) {
          this.$root.$emit("showCustomError", "Не удалось изменить пароль");
        } finally {
          this.resetState();
        }
      } else this.$root.$emit("showCustomError", "Ошибка: пароли не совпадают");
    },
  },
};
</script>

<style scoped>
.create-item {
  width: 240px;
  height: 41px;

  font-family: Gilroy-Medium, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #ffffff;
  text-transform: none !important;
}
.dialog-title {
  font-family: Gilroy-Medium, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 31px;
  text-align: center;
  color: #3474d3;
}
</style>
