<template>
  <v-container fluid>
    <v-row class="options" align="center">
      <v-col cols="auto" class="pl-4">
        <p class="title">Персоны</p>
      </v-col>
      <div style="width: 110px">
        <v-col cols="12">
          <div class="input-wrapper">
            <v-select
              @input="getPersons(1)"
              :items="pagination.limits"
              v-model="pagination.limit"
              hide-spin-buttons
              hide-selected
              hide-details
              outlined
            />
          </div>
        </v-col>
      </div>
      <v-spacer />
      <v-col cols="5">
        <v-text-field
          @input="getPersons(1)"
          v-model="search"
          background-color="#ffffff"
          prepend-inner-icon="mdi-magnify"
          class="rounded-xl"
          hide-spin-buttons
          hide-details
          outlined
          dense
        />
      </v-col>
      <v-col cols="auto">
        <create-person
          v-if="canUser('person-c')"
          @refreshData="getPersons(pagination.page)"
          :branches="branches"
        />
      </v-col>
      <v-col cols="auto">
        <upload-persons
          v-if="canUser('person-c')"
          @refreshData="getPersons(pagination.page)"
        />
      </v-col>
    </v-row>
    <v-row class="options" align="center">
      <v-col cols="auto" class="pl-4">
        <p class="label">Группа:</p>
      </v-col>
      <div style="width: 330px">
        <div class="input-wrapper">
          <v-select
            @input="getPersons(1)"
            :items="groups"
            v-model="group_id"
            item-text="title"
            item-value="id"
            hide-spin-buttons
            hide-details
            clearable
            outlined
            dense
          />
        </div>
      </div>
    </v-row>
    <v-data-table
      class="elevation-1"
      :headers="headers"
      :items="items"
      :items-per-page="pagination.limit"
      :loading="loading"
      loading-text="Пожалуйста подождите, данные загружаются..."
      hide-default-footer
    >
      <template v-slot:item.ashyq_status="{ item }">
        <div v-if="item.ashyq_status === 'Красный статус'">
          <p class="red--text">{{ item.ashyq_status }}</p>
        </div>
        <div v-else-if="item.ashyq_status === 'Желтый статус'">
          <p class="yellow--text">{{ item.ashyq_status }}</p>
        </div>
        <div v-else-if="item.ashyq_status === 'Зеленый статус'">
          <p class="green--text">{{ item.ashyq_status }}</p>
        </div>
        <div v-else>
          <p class="blue--text">{{ item.ashyq_status }}</p>
        </div>
      </template>
      <template v-slot:item.full_name="{ item }">
        {{ item.last_name }} {{ item.first_name }} {{ item.patronymic }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <detail-persons :id="item.id" />
        <update-person-photo
          v-if="canUser('person-u')"
          @refreshData="getPersons(pagination.page)"
          :person="item"
        />
        <update-person
          v-if="canUser('person-u')"
          @refreshData="getPersons(pagination.page)"
          :person="item"
          :branches="branches"
          :groups="groups"
        />
        <AttendancePerson :id="item.id" />
        <delete-item
          v-if="canUser('person-d')"
          :delete-item="deleteItem(item)"
          @deletePerson="deletePerson(item)"
        />
      </template>
    </v-data-table>
    <custom-pagination
      @updatePage="getPersons($event)"
      :pagination="pagination"
    />
  </v-container>
</template>

<script>
import DeleteItem from "@/components/DeleteItem";
import UploadPersons from "@/components/Persons/UploadPersons";
import CustomPagination from "@/components/CustomPagination";
import CreatePerson from "@/components/Persons/CreatePerson";
import UpdatePerson from "@/components/Persons/UpdatePerson";
import DetailPersons from "@/components/Persons/DetailPersons";
import UpdatePersonPhoto from "@/components/Persons/UpdatePersonPhoto";
import AttendancePerson from "@/components/Persons/AttendancePerson";
export default {
  name: "Persons",
  components: {
    AttendancePerson,
    UpdatePersonPhoto,
    DetailPersons,
    UpdatePerson,
    CreatePerson,
    CustomPagination,
    UploadPersons,
    DeleteItem,
  },
  data() {
    return {
      loading: false,
      headers: [
        {
          text: "Группа",
          align: "start",
          sortable: true,
          value: "group.title",
        },
        {
          text: "ФИО",
          align: "start",
          sortable: true,
          value: "full_name",
        },
        {
          text: "ИИН",
          align: "start",
          sortable: true,
          value: "iin",
        },
        {
          text: "Организация",
          align: "start",
          sortable: true,
          value: "organization.title",
        },
        {
          text: "Филиал",
          align: "start",
          sortable: true,
          value: "branch.title",
        },
        {
          text: "Статус ASHYQ",
          align: "start",
          sortable: true,
          value: "ashyq_status",
        },
        {
          text: "Действия",
          align: "center",
          sortable: true,
          value: "actions",
        },
      ],
      items: [],
      pagination: {
        limit: 10,
        limits: [10, 50, 100],
        offset: 0,
        count: 0,
        page: 1,
        pages: 1,
      },
      search: "",
      group_id: "",
      branches: [],
      groups: [],
      cancelToken: null,
    };
  },
  methods: {
    async getPersons(page = 1) {
      if (this.cancelToken != null) this.cancelToken.cancel();
      this.cancelToken = this.$axios.cancelToken.source();
      this.loading = true;
      this.items = [];
      this.pagination.page = page;
      this.pagination.offset =
        (this.pagination.page - 1) * this.pagination.limit;
      try {
        const response = await this.$axios.get("persons/", {
          cancelToken: this.cancelToken.token,
          params: {
            limit: this.pagination.limit,
            offset: this.pagination.offset,
            group_id: this.group_id,
            full_name: this.search,
            is_guest: false,
          },
        });
        this.items = response.data.results;
        this.loading = false;
        this.pagination.count = response.data.count;
        this.pagination.pages =
          Math.floor(this.pagination.count / this.pagination.limit) + 1;
      } catch (e) {
        if (e.status) {
          this.loading = false;
          this.$root.$emit("showDefaultError");
        }
      }
    },
    async deletePerson(person) {
      try {
        await this.$axios.post(`persons/${person.id}/delete/`);
        await this.getPersons(this.pagination.page);
      } catch (e) {
        this.$root.$emit("showDefaultError");
      }
    },
    async getBranches() {
      try {
        const response = await this.$axios.get("organizations/branches/short/");
        response.data;
        this.branches = response.data;
      } catch (e) {
        this.$root.$emit("showDefaultError");
      }
    },
    async getGroups() {
      try {
        const response = await this.$axios.get("groups/short/", {
          params: {
            branch_id: this.$store.state.user.branch.id,
          },
        });
        this.groups = response.data;
      } catch (e) {
        this.$root.$emit("showDefaultError");
      }
    },
    deleteItem(item) {
      return {
        name: item.iin,
        action: "deletePerson",
      };
    },
  },
  async created() {
    await this.getGroups();
    this.group_id = this.$route.params.group;
    await this.getPersons();
    await this.getBranches();
  },
};
</script>

<style scoped></style>
