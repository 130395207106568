const privilegesMixin = {
  methods: {
    canUser(privilege) {
      const privileges = this.$store.state.user.privileges;
      for (let i = 0; i < privileges.length; i++) {
        if (privileges[i].code === privilege) {
          return true;
        }
      }
      return false;
    },
  },
};

export default privilegesMixin;
