<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="btn-text"
        v-bind="attrs"
        v-on="on"
        color="#2CBF56"
        style="text-transform: none"
        rounded
        >Создать отчёт
        <v-icon class="ml-2" size="20">mdi-plus-circle</v-icon>
      </v-btn>
    </template>
    <v-card class="rounded-xl" style="overflow: hidden">
      <v-row>
        <v-col>
          <v-spacer />
        </v-col>
        <v-card-title class="title_color" style="padding-top: 20px">
          Создать отчёт
        </v-card-title>
        <v-col
          style="
            display: flex;
            flex-direction: row-reverse;
            margin-right: 30px;
            margin-top: 10px;
          "
        >
          <v-btn @click="resetState" icon>
            <v-icon large>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-card-text style="padding: 15px 40px">
        <v-form v-model="valid" valid>
          <v-row justify="space-around">
            <v-col cols="auto">
              <v-menu
                v-model="menuFrom"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <label>Дата начала </label>
                  <div class="input-wrapper" style="width: 150px">
                    <v-text-field
                      v-model="params.fromDate"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                    ></v-text-field>
                  </div>
                </template>
                <v-date-picker
                  v-model="params.fromDate"
                  scrollable
                  @input="menuFrom = false"
                ></v-date-picker> </v-menu
            ></v-col>
            <v-col cols="auto">
              <v-menu
                v-model="menuTo"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <label>Дата конца </label>
                  <div class="input-wrapper" style="width: 160px">
                    <v-text-field
                      v-model="params.toDate"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                    ></v-text-field>
                  </div>
                </template>
                <v-date-picker
                  v-model="params.toDate"
                  scrollable
                  @input="menuTo = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-col style="padding-left: 50px">
            <div class="input-wrapper">
              <label>Отчет по Филиалу ? </label>
              <v-checkbox class="pl-2" @click="changeIsGroup" hide-details />
            </div>
          </v-col>
          <v-col v-if="isGroup === false" style="padding-left: 50px">
            <div class="input-wrapper">
              <label>Группа </label>
              <v-select
                :items="groups"
                v-model="group_id"
                item-text="title"
                item-value="id"
                clearable
                hide-selected
                hide-details
                outlined
              ></v-select>
            </div>
          </v-col>
          <v-col>
            <v-btn
              style="text-transform: none"
              class="white--text"
              block
              @click="creteReports"
              :disabled="!valid"
              color="#3474D3"
              >Сохранить</v-btn
            >
          </v-col>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CreateReports",
  data: () => {
    return {
      reports: {},
      groups: [],
      group_id: null,
      isGroup: false,
      dialog: false,
      valid: false,
      rules: {
        required: [(v) => !!v || "Поле обязательно для заполнения"],
      },
      menuFrom: false,
      menuTo: false,
      params: {
        fromDate: new Date().toISOString().substr(0, 10),
        toDate: new Date(new Date().setDate(new Date().getDate() + 1))
          .toISOString()
          .substr(0, 10),
      },
    };
  },
  methods: {
    resetState() {
      this.reports = {};
      this.dialog = false;
    },
    async creteReports() {
      try {
        await this.$axios.post(
          "reports/create/",
          {
            group_id: this.group_id,
          },
          {
            params: {
              from_date: this.params.fromDate,
              to_date: this.params.toDate,
            },
          }
        );
        this.$root.$emit("showCustomSuccess", "Отчёт успешно создан!");
        this.$emit("refreshData");
      } catch (result) {
        this.$root.$emit("showCustomError", result);
      } finally {
        this.resetState();
      }
    },
    async getGroups() {
      try {
        const response = await this.$axios.get("groups/short/");
        this.groups = response.data;
      } catch (e) {
        this.$root.$emit("showCustomError", `Ошибка при получение групп`);
      }
    },

    changeIsGroup() {
      this.isGroup = !this.isGroup;
      if (!this.isGroup) this.group_id = null;
    },
  },
  created() {
    this.getGroups();
  },
};
</script>

<style scoped>
.btn-text {
  width: 240px;
  height: 41px;

  font-family: Gilroy-Medium, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #ffffff;
  text-transform: none;
}
</style>
