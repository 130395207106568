<template>
  <v-container fluid class="px-5">
    <v-row class="options" align="center">
      <v-col cols="auto" class="pl-7">
        <p class="title">Роли</p>
      </v-col>
      <div style="width: 110px">
        <v-col cols="12">
          <div class="input-wrapper">
            <v-select
              @input="getRoles(1)"
              :items="pagination.limits"
              v-model="pagination.limit"
              hide-spin-buttons
              hide-selected
              hide-details
              outlined
            />
          </div>
        </v-col>
      </div>
      <v-spacer />
      <v-col cols="auto">
        <create-roles
          v-if="canUser('role-c')"
          @refreshData="getRoles"
          :privileges="privileges"
        />
      </v-col>
    </v-row>
    <v-data-table
      class="elevation-1"
      :headers="headers"
      :items="roles"
      :items-per-page="pagination.limit"
      :loading="loading"
      :search="search"
      loading-text="Пожалуйста подождите, данные загружаются..."
      hide-default-footer
    >
      <template v-slot:item.is_superuser="{ item }">
        {{ item.is_superuser ? "Да" : "Нет" }}
      </template>
    </v-data-table>
    <custom-pagination
      @updatePage="getRoles($event)"
      :pagination="pagination"
    />
  </v-container>
</template>

<script>
import CustomPagination from "@/components/CustomPagination";
import CreateRoles from "@/components/Roles/CreateRoles";

export default {
  name: "Roles",
  components: {
    CreateRoles,
    CustomPagination,
  },
  data: () => {
    return {
      loading: true,
      roles: [],
      headers: [
        {
          text: "Наиминование роли",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Права и привилегии",
          align: "start",
          sortable: false,
          value: "privileges.title",
        },
        {
          text: "Описание",
          align: "start",
          sortable: false,
          value: "description",
        },
        {
          text: "Супер пользователь",
          align: "center",
          sortable: false,
          value: "is_superuser",
        },
      ],
      privileges: [],
      pagination: {
        limit: 10,
        limits: [10, 50, 100],
        offset: 0,
        count: 0,
        page: 1,
        pages: 1,
      },
      search: "",
    };
  },
  methods: {
    async getRoles(page = 1) {
      this.loading = true;
      this.roles = [];
      this.pagination.page = page;
      this.pagination.offset =
        (this.pagination.page - 1) * this.pagination.limit;
      try {
        const response = await this.$axios.get("privileges/roles/", {
          params: {
            limit: this.pagination.limit,
            offset: this.pagination.offset,
          },
        });
        this.roles = response.data.results;
        this.loading = false;
        this.pagination.count = response.data.count;
        this.pagination.pages =
          Math.floor(this.pagination.count / this.pagination.limit) + 1;
      } catch (e) {
        this.loading = false;
        this.$root.$emit("showDefaultError");
      }
    },

    async deleteRoles(roles) {
      try {
        await this.$axios.post(`privileges/roles/${roles.id}/delete/`);
        this.$root.$emit("showCustomSuccess", "Роль успешно удалена!");
        await this.getRoles(this.pagination.page);
      } catch (e) {
        this.$root.$emit(
          "showCustomError",
          `Ошибка во время удаления роли ${roles.id}`
        );
      }
    },

    async getPrivileges() {
      try {
        const response = await this.$axios.get("/privileges/short/");
        this.privileges = response.data;
      } catch (e) {
        this.$root.$emit(
          "showCustomError",
          "Ошибка во время получения привилегий"
        );
      }
    },

    deleteItem(item) {
      return {
        name: item.id,
        action: "deleteDevice",
      };
    },
  },
  mounted() {
    this.getRoles();
    this.getPrivileges();
  },
};
</script>

<style scoped></style>
