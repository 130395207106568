<template>
  <v-dialog v-model="dialog" width="400" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" icon>
        <v-icon color="error"> mdi-trash-can </v-icon>
      </v-btn>
    </template>
    <v-card class="rounded-lg" style="overflow: hidden">
      <v-row>
        <v-col>
          <v-spacer />
        </v-col>
        <v-card-title class="title_color title" style="padding-top: 30px">
          Объект будет удален
        </v-card-title>
        <v-col
          style="
            display: flex;
            flex-direction: row-reverse;
            margin-right: 15px;
            margin-top: 10px;
          "
        >
          <v-btn @click="dialog = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-card-text class="text-center main_text">
        Вы уверены что хотите удалить объект:<br />
        <span class="font-weight-bold">{{ deleteItem.name }}</span> ?
      </v-card-text>
      <v-card-actions>
        <v-row justify="space-around" align="center">
          <v-col cols="auto">
            <v-btn
              class="main_text"
              @click="dialog = false"
              style="text-transform: none; color: blue"
              text
              >Отмена</v-btn
            >
          </v-col>
          <v-col cols="auto">
            <v-btn
              class="main_text"
              @click="yesAction"
              style="text-transform: none; color: white; border-radius: 8px"
              color="blue"
              >Да, удалить</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DeleteItem",
  props: {
    deleteItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    yesAction() {
      this.dialog = false;
      this.$emit(this.deleteItem.action);
    },
  },
};
</script>

<style scoped>
.title {
  font-family: "Gilroy-Medium", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 31px;
  color: #3474d3;
}
.main_text {
  font-family: "Gilroy-Medium", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #2c2c32;
}
</style>
