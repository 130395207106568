<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        @click="setDate"
        v-bind="attrs"
        v-on="on"
        class="create-item"
        color="#2CBF56"
        depressed
        rounded
      >
        Новый гость
        <v-icon class="ml-2" size="20">mdi-plus-circle</v-icon>
      </v-btn>
    </template>
    <v-card class="rounded-lg">
      <v-card-title class="pb-0">
        <v-row align="center" justify="space-between">
          <v-col class="px-0" cols="auto"></v-col>
          <v-col class="px-0" cols="auto">
            <p class="dialog-title">Создание гостя</p>
          </v-col>
          <v-col class="px-0" cols="auto">
            <v-btn icon @click="resetState">
              <v-icon size="30" color="error">mdi-close-thick</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text style="padding: 15px 40px">
        <v-form ref="form" v-model="valid">
          <div class="d-flex flex-row justify-space-between">
            <div class="input-wrapper" style="width: 48%">
              <label for="first_name"
                >Имя <span class="red--text">*</span>
              </label>
              <v-text-field
                autocomplete="given-name"
                placeholder="Введите имя персоны"
                v-model="newGuest.first_name"
                id="first_name"
                :rules="rules.required"
                outlined
                dense
              />
            </div>
            <div class="input-wrapper" style="width: 48%">
              <label for="last_name"
                >Фамилия <span class="red--text">*</span>
              </label>
              <v-text-field
                autocomplete="family-name"
                placeholder="Введите фамилию персоны"
                v-model="newGuest.last_name"
                id="last_name"
                :rules="rules.required"
                outlined
                dense
              />
            </div>
          </div>
          <div class="input-wrapper">
            <label for="patronymic">Отчество </label>
            <v-text-field
              autocomplete="additional-name"
              placeholder="Введите отчество персоны"
              v-model="newGuest.patronymic"
              id="patronymic"
              outlined
              dense
            />
          </div>
          <div class="input-wrapper" style="width: 48%">
            <label for="iin">ИИН <span class="red--text">*</span> </label>
            <v-text-field
              placeholder="Введите ИИН персоны"
              v-model="newGuest.iin"
              :rules="rules.individual_identifier"
              id="iin"
              counter
              maxlength="12"
              hide-spin-buttons
              outlined
              dense
            />
          </div>
          <div class="input-wrapper">
            <label for="branch">Филиал <span class="red--text">*</span> </label>
            <v-select
              @change="
                getGroups();
                getDevices();
              "
              placeholder="Выберите филиал"
              v-model="newGuest.branch_id"
              :rules="rules.required"
              :items="branches"
              item-text="title"
              item-value="id"
              id="branch"
              outlined
              dense
            />
          </div>
          <div v-if="newGuest.branch_id" class="input-wrapper">
            <label for="group">Группа</label>
            <v-select
              placeholder="Выберите группу"
              v-model="newGuest.group_id"
              :items="groups"
              :rules="rules.required"
              item-text="title"
              item-value="id"
              id="group"
              outlined
              dense
            />
          </div>
          <div v-if="newGuest.branch_id" class="input-wrapper">
            <label for="devices">Планшет</label>
            <v-autocomplete
              placeholder="Выберите планшеты"
              v-model="newGuest.devices"
              :items="devices"
              :rules="rules.required"
              :search-input.sync="searchDevice"
              item-text="device_key"
              item-value="id"
              id="devices"
              multiple
              outlined
              deletable-chips
              small-chips
              dense
              ref="select"
            >
              <template #append-item>
                <div class="append">
                  <v-btn @click="$refs.select.blur()" color="primary">OK</v-btn>
                </div>
              </template>
            </v-autocomplete>
          </div>
          <div class="input-wrapper" style="width: 48%">
            <label for="telegram_id">Телеграм ID </label>
            <v-text-field
              placeholder="Введите телеграм ID персоны"
              v-model="newGuest.telegram_id"
              id="telegram_id"
              outlined
              dense
            />
          </div>
          <div class="d-flex flex-row justify-space-between">
            <div class="input-wrapper" style="width: 48%">
              <label for="date">Дата удаления с планшета</label>
              <v-text-field
                placeholder="Введите дату"
                v-model="date"
                id="date"
                outlined
                dense
              />
            </div>
            <div class="input-wrapper" style="width: 48%">
              <label for="time">Время удаления с планшета</label>
              <v-text-field
                placeholder="Введите дату"
                v-model="time"
                id="time"
                outlined
                dense
              />
            </div>
          </div>
          <div class="input-wrapper">
            <label for="facePhoto"
              >Фотография персоны <span class="red--text">*</span>
            </label>
            <label class="input" v-if="!newGuest.face_photo">
              <input
                @change="onFileChange($event)"
                type="file"
                accept="image/png, image/jpeg"
              />
              Загрузить
              <v-icon color="#3474d3" size="20" class="pl-2"
                >mdi-arrow-down-circle-outline</v-icon
              >
            </label>
            <div class="preview" v-else>
              <v-img height="125" width="125" :src="image" />
              <v-btn class="delete-btn" @click="removeImage" icon>
                <v-icon color="error">mdi-delete</v-icon>
              </v-btn>
            </div>
          </div>
        </v-form>

        <v-btn
          @click="create"
          :loading="loading"
          :disabled="!valid"
          class="save-btn"
          depressed
          >Сохранить</v-btn
        >
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CreateGuest",
  props: {
    branches: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      valid: false,
      loading: false,
      newGuest: {
        iin: "",
        branch_id: "",
        group_id: "",
        first_name: "",
        last_name: "",
        patronymic: "",
        telegram_id: "",
        face_photo: "",
        card_id: 12345,
        devices: [],
        is_guest: true,
        expire_at: new Date(),
      },
      searchDevice: "",
      groups: [],
      devices: [],
      image: "",
      date: null,
      time: null,

      rules: {
        required: [
          (v) => {
            if (v) if (0 < v.length <= 255) return true;
            return "Поле обязательно для заполнения";
          },
        ],
        mobile_phone: [
          (v) => {
            if (v && v?.length > 0) {
              return (
                /^[+7]\d{10}/.test(v) ||
                "Номер должен соответсовать следующему формату: +77001112233"
              );
            }
            return "Поле обязательно для заполнения";
          },
        ],
        individual_identifier: [
          (v) => {
            if (v && v.length > 0) {
              return /^\d{12}/.test(v) || "Введите корректный ИИН";
            }
            return "Поле обязательно для заполнения";
          },
        ],
      },
    };
  },
  methods: {
    resetState() {
      this.newGuest = Object.assign(
        {},
        {
          iin: "",
          branch_id: "",
          group_id: "",
          first_name: "",
          last_name: "",
          patronymic: "",
          telegram_id: "",
          face_photo: "",
          card_id: 12345,
          devices: [],
          is_guest: true,
          expire_at: new Date(),
        }
      );
      this.loading = false;
      this.$refs.form.reset();
      this.dialog = false;
    },
    setDate() {
      const date = new Date();
      let [day, month, year] = date.toLocaleDateString("ru-RU").split(".");
      day = day.length > 1 ? day : `0${day}`;
      month = month.length > 1 ? month : `0${month}`;
      this.date = `${year}-${month}-${day}`;
      this.time = date.toLocaleTimeString("ru-RU", { hour12: false });
    },
    async create() {
      this.loading = true;
      this.newGuest.expire_at = `${this.date}T${this.time}`;
      if (this.newGuest.patronymic === "") delete this.newGuest.patronymic;
      let formData = new FormData();
      for (let o in this.newGuest) {
        if (this.newGuest[o] !== null) formData.append(o, this.newGuest[o]);
      }
      try {
        await this.$axios.post("persons/create/", formData);
        this.$root.$emit(
          "showCustomSuccess",
          `Персона ${this.newGuest.first_name} был успешно создан`
        );
        this.$emit("refreshData");
      } catch (result) {
        this.$root.$emit("showCustomError", result);
      } finally {
        this.resetState();
      }
    },
    async getDevices() {
      try {
        const response = await this.$axios.get("devices/short/");
        this.devices = response.data;
      } catch (e) {
        this.$root.$emit(
          "showCustomError",
          "Ошибка во время получения списка устройств"
        );
      }
    },
    async getGroups() {
      try {
        const response = await this.$axios.get("groups/short/", {
          params: {
            branch_id: this.newGuest.branch_id,
          },
        });
        this.groups = response.data;
      } catch (e) {
        this.$root.$emit("showDefaultError");
      }
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      const reader = new FileReader();
      const vm = this;
      this.newGuest.face_photo = file;

      reader.onload = (e) => {
        vm.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    removeImage() {
      this.newGuest.face_photo = "";
    },
    test() {
      console.log(this.newGuest.devices);
    },
  },
};
</script>

<style scoped lang="scss">
.create-item {
  width: auto;
  height: 41px;

  font-family: Gilroy-Medium, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #ffffff;
  text-transform: none !important;
}

.dialog-title {
  font-family: Gilroy-Medium, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 31px;
  text-align: center;
  color: #3474d3;
}

.preview {
  position: relative;
  height: 125px;
  width: 125px;

  .delete-btn {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.append {
  position: sticky;
  bottom: 8px;
  width: 100%;
  display: flex;
  justify-content: center;
  background: white;
}
</style>
