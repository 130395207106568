<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn @click="copyObject" v-bind="attrs" v-on="on" icon>
        <v-icon color="blue">mdi-square-edit-outline</v-icon>
      </v-btn>
    </template>
    <v-card class="rounded-lg">
      <v-card-title class="pb-0">
        <v-row align="center" justify="space-between">
          <v-col class="px-0" cols="auto"></v-col>
          <v-col class="px-0" cols="auto">
            <p class="dialog-title">Редактирование персоны</p>
          </v-col>
          <v-col class="px-0" cols="auto">
            <v-btn icon @click="resetState">
              <v-icon size="30" color="error">mdi-close-thick</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text style="padding: 15px 40px">
        <v-form ref="form" v-model="valid">
          <div class="d-flex flex-row justify-space-between">
            <div class="input-wrapper" style="width: 48%">
              <label for="first_name"
                >Имя <span class="red--text">*</span>
              </label>
              <v-text-field
                autocomplete="given-name"
                placeholder="Введите имя персоны"
                v-model="updatedPerson.first_name"
                id="first_name"
                :rules="rules.required"
                outlined
                dense
              />
            </div>
            <div class="input-wrapper" style="width: 48%">
              <label for="last_name"
                >Фамилия <span class="red--text">*</span>
              </label>
              <v-text-field
                autocomplete="family-name"
                placeholder="Введите фамилию персоны"
                v-model="updatedPerson.last_name"
                id="last_name"
                :rules="rules.required"
                outlined
                dense
              />
            </div>
          </div>
          <div class="input-wrapper">
            <label for="patronymic">Отчество </label>
            <v-text-field
              autocomplete="additional-name"
              placeholder="Введите фамилию персоны"
              v-model="updatedPerson.patronymic"
              id="patronymic"
              outlined
              dense
            />
          </div>
          <div class="d-flex flex-row justify-space-between">
            <div class="input-wrapper">
              <label for="iin">ИИН <span class="red--text">*</span> </label>
              <v-text-field
                placeholder="Введите ИИН пользователя"
                v-model="updatedPerson.iin"
                :rules="rules.individual_identifier"
                id="iin"
                counter
                maxlength="12"
                hide-spin-buttons
                outlined
                dense
              />
            </div>
            <div class="input-wrapper" style="width: 48%">
              <label for="last_name">Должность </label>
              <v-text-field
                autocomplete="family-name"
                placeholder="Введите должность"
                v-model="updatedPerson.position"
                id="last_name"
                outlined
                dense
              />
            </div>
          </div>
          <div class="input-wrapper">
            <label for="branch">Филиал <span class="red--text">*</span> </label>
            <v-select
              @change="getGroups"
              placeholder="Выберите филиал"
              v-model="updatedPerson.branch_id"
              :rules="rules.required"
              :items="branches"
              item-text="title"
              item-value="id"
              id="branch"
              outlined
              dense
            />
          </div>
          <div class="input-wrapper">
            <label for="group">Группа</label>
            <v-select
              @input="getGroups"
              placeholder="Выберите группу"
              v-model="updatedPerson.group_id"
              :items="groups"
              item-text="title"
              item-value="id"
              id="group"
              outlined
              dense
            />
          </div>
          <!--          <div v-if="updatedPerson.branch_id" class="input-wrapper">-->
          <!--            <label for="devices">Планшеты</label>-->
          <!--            <v-autocomplete-->
          <!--              placeholder="Выберите планшеты"-->
          <!--              v-model="updatedPerson.devices"-->
          <!--              :items="devices"-->
          <!--              :rules="rules.required"-->
          <!--              :search-input.sync="searchDevice"-->
          <!--              item-text="device_key"-->
          <!--              item-value="id"-->
          <!--              id="devices"-->
          <!--              multiple-->
          <!--              outlined-->
          <!--              deletable-chips-->
          <!--              small-chips-->
          <!--              dense-->
          <!--              ref="select"-->
          <!--            >-->
          <!--              <template #append-item>-->
          <!--                <div class="append">-->
          <!--                  <v-btn @click="$refs.select.blur()" color="primary">OK</v-btn>-->
          <!--                </div>-->
          <!--              </template>-->
          <!--            </v-autocomplete>-->
          <!--          </div>-->
          <div v-if="updatedPerson.branch_id" class="input-wrapper">
            <label for="master">Наблюдатель</label>
            <v-autocomplete
              @change="getPersons"
              placeholder="Выберите наблюдателя"
              v-model="updatedPerson.supervisor_ids"
              :items="persons"
              :search-input.sync="searchPersons"
              item-text="full_name"
              item-value="id"
              id="master"
              multiple
              outlined
              deletable-chips
              small-chips
              dense
              ref="select"
            >
              <template #append-item>
                <div class="append">
                  <v-btn @click="$refs.select.blur()" color="primary">OK</v-btn>
                </div>
              </template>
            </v-autocomplete>
          </div>
          <div class="d-flex flex-row justify-space-between">
            <div class="input-wrapper" style="width: 48%">
              <label for="telegram_id">Телеграм ID </label>
              <v-text-field
                placeholder="Введите имя персоны"
                v-model="updatedPerson.telegram_id"
                id="telegram_id"
                type="number"
                hide-spin-buttons
                outlined
                dense
              />
            </div>
            <div class="input-wrapper" style="width: 48%">
              <label for="card_id">ID Карты </label>
              <v-text-field
                placeholder="Введите фамилию персоны"
                v-model="updatedPerson.card_id"
                id="card_id"
                outlined
                dense
              />
            </div>
          </div>
          <div class="d-flex flex-row justify-space-between">
            <div class="input-wrapper" style="width: 48%">
              <v-menu
                v-model="menuFrom"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <label for="telegram_id">Дата начала отпуска </label>
                  <div class="input-wrapper" style="width: 160px">
                    <v-text-field
                      v-model="updatedPerson.holiday_starts"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                    ></v-text-field>
                  </div>
                </template>
                <v-date-picker
                  v-model="updatedPerson.holiday_starts"
                  scrollable
                  @input="menuFrom = false"
                ></v-date-picker>
              </v-menu>
            </div>
            <div class="input-wrapper" style="width: 48%">
              <v-menu
                v-model="menuTo"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <label for="card_id">Дата конца отпуска </label>
                  <div class="input-wrapper" style="width: 160px">
                    <v-text-field
                      v-model="updatedPerson.holiday_ends"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                    ></v-text-field>
                  </div>
                </template>
                <v-date-picker
                  v-model="updatedPerson.holiday_ends"
                  scrollable
                  @input="menuTo = false"
                ></v-date-picker>
              </v-menu>
            </div>
          </div>
        </v-form>
        <v-btn
          @click="update"
          :disabled="!valid"
          :loading="loading"
          class="save-btn"
          depressed
          >Сохранить</v-btn
        >
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "UpdatePerson",
  props: {
    person: {
      type: Object,
      required: true,
    },
    branches: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      valid: false,
      loading: false,
      groups: [],
      devices: [],
      persons: [],
      updatedPerson: {},
      searchDevice: "",
      searchPersons: "",
      menuFrom: false,
      menuTo: false,
      rules: {
        select: [
          (v) => {
            if (!!v && Object.entries(v).length > 0) return true;
            return "Поле обязательно для заполнения";
          },
        ],
        required: [
          (v) => {
            if (v) if (0 < v.length <= 255) return true;
            return "Поле обязательно для заполнения";
          },
        ],
      },
    };
  },
  methods: {
    copyObject() {
      Object.assign(this.updatedPerson, this.person);
      delete this.updatedPerson.branch;
      delete this.updatedPerson.group;
      delete this.updatedPerson.ashyq_status;
      delete this.updatedPerson.device;
      delete this.updatedPerson.id;
      delete this.updatedPerson.organization;
      this.updatedPerson.branch_id = this.person.branch.id;
      this.updatedPerson.group_id = this.person.group?.id;
      // this.updatedPerson.devices = this.person.device.map((i) => i.id);
      this.getGroups();
      this.getPersons();
      // this.getDevices();
      console.log(this.updatedPerson);
    },
    resetState() {
      this.groups = [];
      this.devices = [];
      this.updatedPerson = {};
      this.loading = false;
      this.$refs.form.reset();
      this.dialog = false;
    },
    async update() {
      this.loading = true;
      try {
        if (this.updatedPerson.patronymic === "")
          delete this.updatedPerson.patronymic;
        this.$axios.post(`/persons/${this.person.id}/update/`, {
          ...this.updatedPerson,
        });
        this.$root.$emit(
          "showCustomSuccess",
          `Персона ${this.updatedPerson.first_name} был успешно обновлен`
        );
        this.$emit("refreshData");
      } catch (result) {
        this.$root.$emit("showCustomError", result);
      } finally {
        this.resetState();
      }
    },
    async getGroups() {
      try {
        const response = await this.$axios.get("groups/short/", {
          params: {
            branch_id: this.updatedPerson.branch_id,
          },
        });
        this.groups = response.data;
      } catch (e) {
        console.log(e);
        this.$root.$emit("showDefaultError");
      }
    },
    // async getDevices() {
    //   try {
    //     const response = await this.$axios.get("devices/short/");
    //     this.devices = response.data;
    //   } catch (e) {
    //     this.$root.$emit(
    //       "showCustomError",
    //       "Ошибка во время получения списка устройств"
    //     );
    //   }
    // },
    async getPersons() {
      try {
        const response = await this.$axios.get("persons/short/", {
          params: {
            organization_id: this.$store.state.user.organization.id,
          },
        });
        this.persons = response.data;
      } catch (e) {
        this.$root.$emit("showDefaultError");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.create-item {
  width: 240px;
  height: 41px;

  font-family: Gilroy-Medium, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #ffffff;
  text-transform: none !important;
}
.dialog-title {
  font-family: Gilroy-Medium, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 31px;
  text-align: center;
  color: #3474d3;
}

.append {
  position: sticky;
  bottom: 8px;
  width: 100%;
  display: flex;
  justify-content: center;
  background: white;
}
</style>
