<template>
  <v-dialog v-model="dialog" width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-btn @click="getPhoto" v-bind="attrs" v-on="on" icon>
        <v-icon color="orange">mdi-image</v-icon>
      </v-btn>
    </template>
    <v-card class="rounded-lg">
      <v-card-title class="pb-0">
        <v-row align="center" justify="space-between">
          <v-col class="px-0" cols="auto"></v-col>
          <v-col class="px-0" cols="auto">
            <p class="dialog-title">Обновление фотографии</p>
          </v-col>
          <v-col class="px-0" cols="auto">
            <v-btn icon @click="dialog = false">
              <v-icon size="30" color="error">mdi-close-thick</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text style="padding: 15px 40px">
        <v-form ref="form">
          <div class="d-flex flex-row justify-space-between">
            <div class="input-wrapper" style="width: 48%">
              <label for="first_name"
                >Имя <span class="red--text">*</span>
              </label>
              <v-text-field
                autocomplete="given-name"
                placeholder="Введите имя персоны"
                v-model="guest.first_name"
                id="first_name"
                disabled
                outlined
                dense
              />
            </div>
            <div class="input-wrapper" style="width: 48%">
              <label for="last_name">Фамилия</label>
              <v-text-field
                autocomplete="family-name"
                placeholder="Введите фамилию персоны"
                v-model="guest.last_name"
                id="last_name"
                disabled
                outlined
                dense
              />
            </div>
          </div>
          <div class="input-wrapper">
            <label for="patronymic">Отчество</label>
            <v-text-field
              autocomplete="additional-name"
              placeholder="Введите фамилию персоны"
              v-model="guest.patronymic"
              id="patronymic"
              disabled
              outlined
              dense
            />
          </div>
          <div class="input-wrapper">
            <label for="facePhoto">
              Фотография персоны <span class="red--text">*</span>
            </label>
            <label class="input" v-if="!face_photo">
              <input
                @change="onFileChange($event)"
                type="file"
                accept="image/png, image/jpeg"
              />
              Загрузить
              <v-icon color="#3474d3" size="20" class="pl-2"
                >mdi-arrow-down-circle-outline</v-icon
              >
            </label>
            <div class="preview" v-else>
              <v-img height="125" width="125" :src="image" />
              <v-btn class="delete-btn" @click="removeImage" icon>
                <v-icon color="error">mdi-delete</v-icon>
              </v-btn>
            </div>
          </div>
        </v-form>

        <v-btn
          @click="update"
          :loading="loading"
          :disabled="disabled"
          class="save-btn"
          depressed
          >Сохранить</v-btn
        >
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "UpdateGuestPhoto",
  props: {
    guest: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      disabled: true,

      image: null,
      face_photo: null,
    };
  },
  methods: {
    async update() {
      this.loading = true;
      let formData = new FormData();
      formData.append("face_photo", this.face_photo);
      try {
        await this.$axios.post(
          `persons/${this.guest.id}/update/face/`,
          formData
        );
        this.$root.$emit(
          "showCustomSuccess",
          `Фотография гостя ${this.guest.first_name} обновлена`
        );
      } catch (e) {
        this.$root.$emit(
          "showCustomError",
          "Ошибка во время обновления фотографии"
        );
        this.$root.$emit("refreshData");
      }
      this.loading = false;
      this.dialog = false;
    },
    async getPhoto() {
      try {
        const response = await axios.get(`persons/${this.guest.id}/details/`);
        this.image = response.data.face_photo;
        this.face_photo = response.data.face_photo;
        this.disabled = true;
      } catch (result) {
        this.$root.$emit("showCustomError", result);
      }
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      const reader = new FileReader();
      const vm = this;
      this.face_photo = file;

      reader.onload = (e) => {
        vm.disabled = false;
        vm.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    removeImage() {
      this.disabled = true;
      this.face_photo = "";
    },
  },
};
</script>

<style scoped lang="scss">
.dialog-title {
  font-family: Gilroy-Medium, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 31px;
  text-align: center;
  color: #3474d3;
}
.preview {
  position: relative;
  height: 125px;
  width: 125px;

  .delete-btn {
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>
