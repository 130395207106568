<template>
  <v-container fluid>
    <v-card
      class="rounded-xl d-flex"
      style="margin-left: 40px; padding-top: 20px; margin-right: 40px"
    >
      <v-col cols="9" class="pa-0">
        <v-card-title style="margin-left: 40px; padding-top: 30px">
          <v-row justify="space-between" align="center">
            <v-col cols="auto title_color header_text">Личные данные</v-col>
          </v-row>
        </v-card-title>
        <v-card-text style="padding: 15px 40px">
          <v-row>
            <v-col cols="5">
              <div class="input-wrapper">
                <label for="last_name"
                  >Фамилия<span class="red--text">*</span>
                </label>
                <v-text-field
                  autocomplete="off"
                  v-model="userProfile.last_name"
                  id="last_name"
                  outlined
                  disabled
                />
              </div>
            </v-col>
            <v-col cols="5">
              <div class="input-wrapper">
                <label for="first_name"
                  >Имя<span class="red--text">*</span>
                </label>
                <v-text-field
                  autocomplete="off"
                  v-model="userProfile.first_name"
                  id="first_name"
                  outlined
                  disabled
                />
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5">
              <div class="input-wrapper">
                <label for="email"
                  >Электронный адрес<span class="red--text">*</span>
                </label>
                <v-text-field
                  autocomplete="off"
                  v-model="userProfile.email"
                  id="email"
                  outlined
                  disabled
                />
              </div>
            </v-col>
            <v-col cols="5">
              <div class="input-wrapper">
                <label for="telegram_id"
                  >Telegram ID<span class="red--text">*</span>
                </label>
                <v-text-field
                  autocomplete="off"
                  v-model="userProfile.telegram_id"
                  id="telegram_id"
                  outlined
                  disabled
                />
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5">
              <div class="input-wrapper">
                <label for="iin">ИИН<span class="red--text">*</span> </label>
                <v-text-field
                  autocomplete="off"
                  v-model="userProfile.iin"
                  id="iin"
                  outlined
                  disabled
                />
              </div>
            </v-col>
          </v-row>
          <v-col cols="8"> <v-divider></v-divider></v-col>
        </v-card-text>
        <v-card-title style="margin-left: 40px; padding-top: 20px">
          <v-row justify="space-between" align="center">
            <v-col cols="auto title_color header_text"
              >Пользовательские данные</v-col
            >
          </v-row>
        </v-card-title>
        <v-card-text style="padding: 15px 40px">
          <v-row>
            <v-col cols="5">
              <div class="input-wrapper">
                <label for="login"
                  >Логин<span class="red--text">*</span>
                </label>
                <v-text-field
                  autocomplete="off"
                  v-model="userProfile.username"
                  id="login"
                  outlined
                  disabled
                />
              </div>
            </v-col>
            <v-col cols="5">
              <div class="input-wrapper">
                <label for="password"
                  >Пароль<span class="red--text">*</span>
                </label>
                <v-text-field
                  autocomplete="off"
                  v-model="userProfile.username"
                  id="password"
                  outlined
                  disabled
                />
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5">
              <div class="input-wrapper">
                <label for="organization"
                  >Организация<span class="red--text">*</span>
                </label>
                <v-text-field
                  autocomplete="off"
                  v-if="organization"
                  v-model="organization.title"
                  id="organization"
                  outlined
                  disabled
                />
              </div>
            </v-col>
            <v-col cols="5">
              <div class="input-wrapper">
                <label for="branch"
                  >Филиал<span class="red--text">*</span>
                </label>
                <v-text-field
                  autocomplete="off"
                  v-if="branch"
                  v-model="branch.title"
                  id="branch"
                  outlined
                  disabled
                />
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="10">
              <v-btn
                style="text-transform: none"
                class="white--text"
                block
                @click="getUserProfile"
                :disabled="true"
                color="#3474D3"
                >Сохранить</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-col>
      <v-col cols="3" style="padding-top: 40px">
        <v-img width="200" height="200" src="@/assets/png/photo.png"></v-img>
        <v-col cols="auto">
          <div class="input-wrapper my-3">
            <label class="ma-0">ID девайса: </label>
            <p class="detail-text">{{ userProfile.id }}</p>
          </div>
        </v-col>
        <v-col cols="auto">
          <div class="input-wrapper my-3">
            <label class="ma-0">Дата создания: </label>
            <p class="detail-text">
              {{ new Date(userProfile.created_at).toLocaleDateString("ru-RU") }}
              {{ new Date(userProfile.created_at).toLocaleTimeString("ru-RU") }}
            </p>
          </div>
        </v-col>
        <v-col cols="auto">
          <div class="input-wrapper my-3">
            <label class="ma-0">Дата редактирование: </label>
            <p class="detail-text">
              {{ new Date(userProfile.created_at).toLocaleDateString("ru-RU") }}
              {{ new Date(userProfile.created_at).toLocaleTimeString("ru-RU") }}
            </p>
          </div>
        </v-col>
        <!--        <v-col cols="auto">-->
        <!--          <ProfileUpdate @refreshData="getUserProfile" />-->
        <!--        </v-col>-->
        <v-col cols="auto">
          <ProfileChangePassword />
        </v-col>
      </v-col>
    </v-card>
  </v-container>
</template>

<script>
import ProfileChangePassword from "@/components/Users/ProfileChangePassword";
export default {
  name: "MyProfile",
  components: { ProfileChangePassword },
  data: () => {
    return {
      userProfile: {},
      organization: {},
      branch: {},
    };
  },
  methods: {
    async getUserProfile() {
      const response = await this.$axios.get("users/myprofile/");
      this.userProfile = response.data;
      this.organization = response.data.organization;
      this.branch = response.data.branch;
    },
  },
  created() {
    this.getUserProfile();
  },
};
</script>

<style scoped></style>
