<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        class="create-item"
        color="#2CBF56"
        depressed
        rounded
      >
        Загрузить персон
        <v-icon class="ml-2" size="20">mdi-plus-circle</v-icon>
      </v-btn>
    </template>
    <v-card class="rounded-lg">
      <v-card-title class="pb-0">
        <v-row align="center" justify="space-between">
          <v-col class="px-0" cols="auto"></v-col>
          <v-col class="px-0" cols="auto">
            <p class="dialog-title">Загрузить персон</p>
          </v-col>
          <v-col class="px-0" cols="auto">
            <v-btn icon @click="dialog = false">
              <v-icon size="30" color="error">mdi-close-thick</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text style="padding: 15px 40px">
        <div class="first-step">
          <p>Шаг 1</p>
          <p>Выберите <strong>xlsx</strong> файл</p>
          <v-file-input
            v-model="file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
          application/vnd.ms-excel"
          />
          <v-btn :loading="fileBtnLoading" :disabled="!file" @click="uploadFile"
            >Upload file</v-btn
          >
        </div>
        <div class="first-step">
          <p>Шаг 2</p>
          <p>Выберите <strong>zip</strong> архив с фотографиями</p>
          <v-file-input v-model="photos" accept=".zip" />
          <v-btn
            :disabled="!photos"
            :loading="zipBtnLoading"
            @click="uploadPhotos"
            >Upload archive</v-btn
          >
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "UploadPersons",
  data() {
    return {
      dialog: false,
      firstSent: false,
      fileBtnLoading: false,
      zipBtnLoading: false,
      photos: null,
      file: null,
    };
  },
  methods: {
    uploadFile() {
      this.fileBtnLoading = true;
      const fd = new FormData();
      fd.append("excel_file", this.file);
      this.$axios
        .post("persons/upload/", fd, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.firstSent = true;
          this.$root.$emit("showCustomSuccess", "Файл успешно загружен!");
          this.fileBtnLoading = false;
        })
        .catch(() => {
          this.$root.$emit("showCustomError", "Не удалось загрузить файл!");
          this.fileBtnLoading = false;
        });
    },
    uploadPhotos() {
      this.zipBtnLoading = true;
      const fd = new FormData();
      fd.append("zip_file", this.photos);
      this.$axios
        .post("persons/upload/faces/", fd, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.dialog = false;
          this.$root.$emit("showCustomSuccess", "Архив успешно загружен!");
          this.zipBtnLoading = false;
          this.$emit("refreshData");
        })
        .catch(() => {
          this.$root.$emit("showCustomError", "Не удалось загрузить архив!");
          this.zipBtnLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.create-item {
  width: 240px;
  height: 41px;

  font-family: Gilroy-Medium, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #ffffff;
  text-transform: none !important;
}
.dialog-title {
  font-family: Gilroy-Medium, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 31px;
  text-align: center;
  color: #3474d3;
}
</style>
