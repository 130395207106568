<template>
  <v-container fluid class="px-5">
    <v-row class="options" align="center">
      <v-col cols="auto" class="pl-7">
        <p class="title">Планшеты</p>
      </v-col>
      <div style="width: 110px">
        <v-col cols="12">
          <div class="input-wrapper">
            <v-select
              :items="pagination.limits"
              v-model="pagination.limit"
              hide-spin-buttons
              hide-selected
              hide-details
              outlined
            />
          </div>
        </v-col>
      </div>
      <v-spacer />

      <v-col cols="auto">
        <CreateDevice
          v-if="canUser('device-c')"
          @refreshData="getDevices"
          :cities="cities"
          :branch="branch"
        />
      </v-col>
    </v-row>
    <v-card-title>
      <v-row justify="start" align="center">
        <v-col cols="auto" class="filter_font">Филиал:</v-col>
        <v-col cols="auto">
          <div class="input-wrapper">
            <v-select
              style="width: 333px"
              :items="branch"
              v-model="params.branch_id"
              @change="getDevices(1)"
              item-text="title"
              item-value="id"
              clearable
              hide-selected
              hide-details
              outlined
            ></v-select>
          </div>
        </v-col>
        <v-col cols="auto" class="filter_font">Город:</v-col>
        <v-col cols="auto">
          <div class="input-wrapper">
            <v-select
              style="width: 333px"
              :items="cities"
              v-model="params.city_id"
              @change="getDevices(1)"
              item-text="name"
              item-value="id"
              clearable
              hide-selected
              hide-details
              outlined
            ></v-select>
          </div>
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table
      class="elevation-1"
      :headers="headers"
      :items="devices"
      :items-per-page="pagination.limit"
      :loading="loading"
      :search="search"
      loading-text="Пожалуйста подождите, данные загружаются..."
      hide-default-footer
    >
      <template v-slot:item.actions="{ item }">
        <DetailDevice v-if="canUser('device-ra')" :id="item.id" />
        <v-btn icon>
          <UpdateDevice
            v-if="canUser('device-u')"
            @refreshData="getDevices"
            :device="devices"
            :device-default="item"
            :branch="branch"
          />
        </v-btn>
        <delete-item
          v-if="canUser('device-d')"
          :delete-item="deleteItem(item)"
          @deleteDevice="deleteDevice(item)"
        />
      </template>
    </v-data-table>
    <custom-pagination
      @updatePage="getDevices($event)"
      :pagination="pagination"
    />
  </v-container>
</template>

<script>
import CreateDevice from "@/components/Devices/CreateDevice";
import UpdateDevice from "@/components/Devices/UpdateDevice";
import DeleteItem from "@/components/DeleteItem";
import DetailDevice from "@/components/Devices/DetailDevice";
import CustomPagination from "@/components/CustomPagination";
export default {
  name: "Devices",
  components: {
    DetailDevice,
    DeleteItem,
    UpdateDevice,
    CreateDevice,
    CustomPagination,
  },
  data: () => {
    return {
      cities: [],
      branch: [],
      devices: [],
      loading: false,
      params: {
        city_id: null,
        branch_id: null,
      },
      headers: [
        {
          text: "Филиал",
          align: "start",
          sortable: true,
          value: "branch",
        },

        {
          text: "Номер планшета",
          align: "start",
          sortable: true,
          value: "device_key",
        },
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: "Город",
          align: "start",
          sortable: true,
          value: "city",
        },
        {
          text: "Статус",
          align: "start",
          sortable: true,
          value: "status",
        },
        {
          text: "Действие",
          align: "center",
          sortable: false,
          value: "actions",
        },
      ],
      pagination: {
        limit: 10,
        limits: [10, 50, 100],
        offset: 0,
        count: 0,
        page: 1,
        pages: 1,
      },
      search: "",
    };
  },
  methods: {
    async getDevices(page = 1) {
      this.loading = true;
      this.devices = [];
      this.pagination.page = page;
      this.pagination.offset =
        (this.pagination.page - 1) * this.pagination.limit;
      try {
        const response = await this.$axios.get("devices/", {
          params: {
            limit: this.pagination.limit,
            offset: this.pagination.offset,
            ...this.params,
          },
        });
        this.devices = response.data.results;
        this.loading = false;
        this.pagination.count = response.data.count;
        this.pagination.pages =
          Math.floor(this.pagination.count / this.pagination.limit) + 1;
      } catch (e) {
        this.loading = false;
        this.$root.$emit("showDefaultError");
      }
    },

    async deleteDevice(device) {
      try {
        await this.$axios.post(`devices/${device.id}/delete/`);
        this.$root.$emit("showCustomSuccess", "Планшет успешно удален!");
        await this.getDevices(this.pagination.page);
      } catch (e) {
        this.$root.$emit(
          "showCustomError",
          `Ошибка во время удаления планшета ${device.id}`
        );
      }
    },

    async getCities() {
      try {
        const response = await this.$axios.get("geo/cities/short/");
        this.cities = response.data;
      } catch (e) {
        this.$root.$emit("showCustomError", `Ошибка при получение городов`);
      }
    },

    async getBranch() {
      try {
        const response = await this.$axios.get("organizations/branches/short/");
        this.branch = response.data;
      } catch (e) {
        this.$root.$emit("showCustomError", `Ошибка при получение филиалов`);
      }
    },

    deleteItem(item) {
      return {
        name: item.id,
        action: "deleteDevice",
      };
    },
  },
  created() {
    this.getDevices();
    this.getCities();
    this.getBranch();
  },
};
</script>

<style scoped>
.filter_font {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
}
</style>
