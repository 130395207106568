<template>
  <v-container fluid>
    <v-row class="options" align="center">
      <v-col cols="auto" class="pl-4">
        <p class="title">Праздники</p>
      </v-col>
      <div style="width: 110px">
        <v-col cols="12">
          <div class="input-wrapper">
            <v-select
              @input="getFiestas(1)"
              :items="pagination.limits"
              v-model="pagination.limit"
              hide-spin-buttons
              hide-selected
              hide-details
              outlined
            />
          </div>
        </v-col>
      </div>
      <v-spacer />
      <v-col cols="auto">
        <create-fiesta
          v-if="canUser('fiesta-c')"
          @refreshData="getFiestas"
          :branches="branch"
        />
      </v-col>
    </v-row>
    <v-data-table
      class="elevation-1"
      :headers="headers"
      :items="fiestas"
      :items-per-page="pagination.limit"
      :loading="loading"
      loading-text="Пожалуйста подождите, данные загружаются..."
      hide-default-footer
    >
      <template v-slot:item.branches="{ item }">
        <v-list width="50%" class="transparent" dense>
          <v-list-group no-action class="transparent">
            <template v-slot:activator>
              <v-list-item-title>Список филиалов</v-list-item-title>
            </template>
            <v-list-item v-for="i in item.branches" :key="i.id">
              {{ i.title }}
            </v-list-item>
          </v-list-group>
        </v-list>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <update-fiesta
          v-if="canUser('fiesta-u')"
          @refreshData="getFiestas(pagination.page)"
          :branches="branch"
          :fiesta-default="item"
        />
        <delete-item
          v-if="canUser('fiesta-d')"
          :delete-item="deleteItem(item)"
          @deleteFiesta="deleteFiesta(item)"
        />
      </template>
    </v-data-table>
    <custom-pagination
      @updatePage="getFiestas($event)"
      :pagination="pagination"
    />
  </v-container>
</template>

<script>
import CustomPagination from "@/components/CustomPagination";
import CreateFiesta from "@/components/Fiestas/CreateFiesta";
import UpdateFiesta from "@/components/Fiestas/UpdateFiesta";
import DeleteItem from "@/components/DeleteItem";
export default {
  name: "Holidays",
  components: { DeleteItem, UpdateFiesta, CreateFiesta, CustomPagination },
  data() {
    return {
      fiestas: [],
      branch: [],
      loading: false,
      headers: [
        {
          text: "Праздник",
          align: "start",
          sortable: true,
          value: "title",
        },
        {
          text: "Филиал",
          align: "start",
          sortable: true,
          value: "branches",
        },
        {
          text: "Дата начала",
          align: "center",
          sortable: true,
          value: "date_from",
        },
        {
          text: "Дата завершения",
          align: "center",
          sortable: true,
          value: "date_to",
        },
        {
          text: "Действие",
          align: "center",
          sortable: false,
          value: "actions",
        },
      ],
      pagination: {
        limit: 10,
        limits: [10, 50, 100],
        offset: 0,
        count: 0,
        page: 1,
        pages: 1,
      },
    };
  },
  methods: {
    async getFiestas(page = 1) {
      this.loading = true;
      this.fiestas = [];
      this.pagination.page = page;
      this.pagination.offset =
        (this.pagination.page - 1) * this.pagination.limit;
      try {
        const response = await this.$axios.get("groups/fiestas/", {
          params: {
            limit: this.pagination.limit,
            offset: this.pagination.offset,
          },
        });
        this.fiestas = response.data.results;
        this.loading = false;
        this.pagination.count = response.data.count;
        this.pagination.pages =
          Math.floor(this.pagination.count / this.pagination.limit) + 1;
      } catch (e) {
        this.loading = false;
        this.$root.$emit("showDefaultError");
      }
    },
    async getBranch() {
      try {
        const response = await this.$axios.get("organizations/branches/short/");
        this.branch = response.data;
      } catch (e) {
        this.$root.$emit("showCustomError", `Ошибка при получение праздника`);
      }
    },

    async deleteFiesta(fiestas) {
      try {
        await this.$axios.post(`groups/fiestas/${fiestas.id}/delete/`);
        this.$root.$emit("showCustomSuccess", "Праздник успешно удален!");
        await this.getFiestas(this.pagination.page);
      } catch (e) {
        this.$root.$emit(
          "showCustomError",
          `Ошибка во время удаления праздника ${fiestas.title}`
        );
      }
    },

    deleteItem(item) {
      return {
        name: item.title,
        action: "deleteFiesta",
      };
    },
  },
  created() {
    this.getFiestas();
    this.getBranch();
  },
};
</script>

<style scoped></style>
