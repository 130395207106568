<template>
  <v-dialog v-model="dialog" width="800" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn @click="getDetails" v-bind="attrs" v-on="on" icon>
        <v-icon color="success">mdi-eye-outline</v-icon>
      </v-btn>
    </template>
    <v-card class="rounded-lg">
      <v-card-title class="pb-0">
        <v-row align="center" justify="space-between">
          <v-col class="px-0" cols="auto"></v-col>
          <v-col class="px-0" cols="auto">
            <p class="dialog-title">Подробный просмотр</p>
          </v-col>
          <v-col class="px-0" cols="auto">
            <v-btn icon @click="dialog = false">
              <v-icon size="30" color="error">mdi-close-thick</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text style="padding: 15px 40px">
        <v-row>
          <v-col cols="6">
            <div class="input-wrapper">
              <label>Серийный ключ <span class="red--text">*</span> </label>
              <v-text-field
                disabled
                autocomplete="off"
                v-model="device.device_key"
                counter="16"
                outlined
                dense
              />
            </div>
            <!--            <div class="input-wrapper">-->
            <!--              <label-->
            <!--                >Секретный пароль для авторизации-->
            <!--                <span class="red&#45;&#45;text">*</span>-->
            <!--              </label>-->
            <!--              <v-text-field-->
            <!--                disabled-->
            <!--                autocomplete="off"-->
            <!--                v-model="device.secret_key"-->
            <!--                outlined-->
            <!--                dense-->
            <!--              />-->
            <!--            </div>-->
            <div class="input-wrapper">
              <label>Филиал <span class="red--text">*</span> </label>
              <v-text-field disabled v-model="device.branch" outlined dense />
            </div>
            <div class="input-wrapper">
              <label>Город <span class="red--text">*</span> </label>
              <v-text-field
                disabled
                v-model="device.city"
                outlined
                dense
              ></v-text-field>
            </div>
            <div class="d-flex flex-row align-center justify-space-between">
              <div class="input-wrapper" style="width: 58%">
                <label>Улица </label>
                <v-text-field
                  disabled
                  autocomplete="off"
                  v-model="device.street"
                  outlined
                  dense
                />
              </div>
              <div class="input-wrapper" style="width: 38%">
                <label>Номер здания </label>
                <v-text-field
                  disabled
                  autocomplete="off"
                  v-model="device.building_number"
                  outlined
                  dense
                />
              </div>
            </div>
            <div class="d-flex flex-row align-center justify-space-between">
              <v-btn @click="synchronizePerson" class="submit-btn" depressed
                >Синхронизаровать <br />
                персон</v-btn
              >
              <v-btn @click="synchronizePhoto" class="submit-btn" depressed
                >Синхронизаровать <br />
                фото</v-btn
              >
            </div>
          </v-col>
          <v-col cols="6">
            <div class="input-wrapper my-3">
              <label class="ma-0">ID девайса: </label>
              <p class="detail-text">{{ device.id }}</p>
            </div>
            <div class="input-wrapper my-3">
              <label class="ma-0">Дата создания: </label>
              <p class="detail-text">
                {{ new Date(device.created_at).toLocaleDateString("ru-RU") }}
                {{ new Date(device.created_at).toLocaleTimeString("ru-RU") }}
              </p>
            </div>
            <div class="input-wrapper my-3">
              <label class="ma-0">Дата обновления: </label>
              <p class="detail-text">
                {{ new Date(device.updated_at).toLocaleDateString("ru-RU") }}
                {{ new Date(device.updated_at).toLocaleTimeString("ru-RU") }}
              </p>
            </div>
            <div class="input-wrapper my-3">
              <label class="ma-0">Статус: </label>
              <p class="detail-text">{{ device.status }}</p>
            </div>
            <div class="input-wrapper my-3">
              <label class="ma-0">Расположение: </label>
              <p class="detail-text">{{ device.entrance_type }}</p>
            </div>
            <div class="input-wrapper my-3">
              <label class="ma-0">Девайс активен: </label>
              <p class="detail-text">{{ device.is_active ? "Да" : "Нет" }}</p>
            </div>
            <div class="input-wrapper my-3">
              <label class="ma-0">Последний ответ: </label>
              <p class="detail-text">
                {{
                  new Date(device.last_heartbeat).toLocaleDateString("ru-RU")
                }}
                {{
                  new Date(device.last_heartbeat).toLocaleTimeString("ru-RU")
                }}
              </p>
            </div>
            <div class="input-wrapper my-3">
              <label class="ma-0">Долгота: </label>
              <p class="detail-text">{{ device.longitude }}</p>
            </div>
            <div class="input-wrapper my-3">
              <label class="ma-0">Широта: </label>
              <p class="detail-text">{{ device.latitude }}</p>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "DetailDevice",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      device: {},
      dialog: false,
    };
  },
  methods: {
    async getDetails() {
      try {
        const response = await axios.get(`devices/${this.id}/details/`);
        this.device = response.data;
      } catch (e) {
        this.$root.$emit("showCustomError", `Ошибка при открытие`);
      }
    },
    async synchronizePerson() {
      const deviceKey = {
        device_key: this.device.device_key,
      };
      try {
        await axios.post(`devices/synchronize/`, deviceKey);
      } catch (e) {
        this.$root.$emit("showCustomError", `Ошибка при синхронизации`);
      }
    },
    async synchronizePhoto() {
      const deviceKey = {
        device_key: this.device.device_key,
      };
      try {
        await axios.post(`devices/synchronize/photos/`, deviceKey);
      } catch (e) {
        this.$root.$emit("showCustomError", `Ошибка при синхронизации фото`);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.dialog-title {
  font-family: Gilroy-Medium, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 31px;
  text-align: center;
  color: #3474d3;
}
.preview {
  position: relative;
  height: 125px;
  width: 125px;

  .delete-btn {
    position: absolute;
    right: 0;
    top: 0;
  }
}
.detail-text {
  font-family: "Gilroy-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}

.submit-btn {
  margin-top: 30px;
  margin-bottom: 10px;
  width: 40%;
  height: 44px !important;

  font-family: Gilroy-Bold, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 21px;
  color: #ffffff !important;

  background: #3474d3 !important;
  box-shadow: 0 4px 20px rgba(62, 61, 87, 0.2);
  border-radius: 8px;
}
</style>
