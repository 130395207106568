<template>
  <v-dialog width="600" v-model="dialog" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        class="create-item"
        color="#2CBF56"
        depressed
        rounded
        >Создать филиал
        <v-icon class="ml-2" size="20">mdi-plus-circle</v-icon>
      </v-btn>
    </template>
    <v-card class="rounded-lg">
      <v-card-title class="pb-0">
        <v-row align="center" justify="space-between">
          <v-col class="px-0" cols="auto"></v-col>
          <v-col class="px-0" cols="auto">
            <p class="dialog-title">Создание филиала</p>
          </v-col>
          <v-col class="px-0" cols="auto">
            <v-btn icon @click="resetState">
              <v-icon size="30" color="error">mdi-close-thick</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text style="padding: 15px 40px">
        <v-form ref="form" v-model="valid" valid>
          <div class="input-wrapper">
            <label for="title"
              >Название Филиала <span class="red--text">*</span>
            </label>
            <v-text-field
              autocomplete="off"
              placeholder="Введите название филиала"
              v-model="branch.title"
              :rules="rules.required"
              id="title"
              outlined
              dense
            />
          </div>
          <div class="input-wrapper">
            <label for="bin"
              >БИН филиала <span class="red--text">*</span>
            </label>
            <v-text-field
              autocomplete="off"
              placeholder="Введите БИН филиала"
              v-model="branch.bin"
              :rules="rules.bin"
              id="bin"
              counter="12"
              outlined
              dense
            />
          </div>
          <div class="d-flex flex-row align-center justify-space-between">
            <div class="input-wrapper" style="width: 48%">
              <label for="city">Город <span class="red--text">*</span> </label>
              <v-select
                placeholder="Выберите город"
                v-model="branch.city_id"
                :rules="rules.required"
                :items="cities"
                item-text="name"
                item-value="id"
                id="city"
                outlined
                dense
              >
              </v-select>
            </div>
            <div class="input-wrapper" style="width: 48%">
              <label for="status"
                >Статус <span class="red--text">*</span>
              </label>
              <v-select
                placeholder="Укажите статус филиала"
                v-model="branch.status"
                :rules="rules.required"
                :items="status"
                item-text="name"
                item-value="id"
                id="status"
                outlined
                dense
              >
              </v-select>
            </div>
          </div>
          <div class="d-flex flex-row align-center justify-space-between">
            <div class="input-wrapper" style="width: 48%">
              <label for="street"
                >Улица <span class="red--text">*</span>
              </label>
              <v-text-field
                autocomplete="off"
                placeholder="Введите название улицы"
                v-model="branch.street"
                :rules="rules.required"
                id="street"
                outlined
                dense
              />
            </div>
            <div class="input-wrapper" style="width: 48%">
              <label for="building_number"
                >Номер здания <span class="red--text">*</span>
              </label>
              <v-text-field
                autocomplete="off"
                placeholder="Номер здания "
                v-model="branch.building_number"
                :rules="rules.required"
                id="building_number"
                outlined
                dense
              />
            </div>
          </div>
          <div class="d-flex flex-row align-center justify-space-between">
            <div class="input-wrapper" style="width: 48%">
              <label for="longitude">Долгота</label>
              <v-text-field
                autocomplete="off"
                placeholder="Долгота"
                v-model="branch.longitude"
                id="longitude"
                outlined
                dense
              />
            </div>
            <div class="input-wrapper" style="width: 48%">
              <label for="latitude">Ширина</label>
              <v-text-field
                autocomplete="off"
                placeholder="Ширина"
                v-model="branch.latitude"
                id="latitude"
                outlined
                dense
              />
            </div>
          </div>
          <v-btn
            @click="createBranch"
            :disabled="!valid"
            :loading="loading"
            class="save-btn"
            depressed
            >Сохранить</v-btn
          >
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CreateBranch",
  props: {
    cities: {
      type: Array,
      required: true,
    },
  },
  data: () => {
    return {
      status: ["Active", "No Active"],
      branch: {
        longitude: 0,
        latitude: 0,
      },
      dialog: false,
      loading: false,
      valid: false,
      rules: {
        required: [(v) => !!v || "Поле обязательно для заполнения"],
        bin: [
          (v) => {
            if (v) {
              if (!/^\d{12}$/.test(v))
                return "Поле должно содержать в себе 12 чисел";
              return true;
            }
            return "Поле обязательно для заполнения";
          },
        ],
      },
    };
  },
  methods: {
    resetState() {
      this.branch = {
        longitude: 0,
        latitude: 0,
      };
      this.dialog = false;
      this.loading = false;
      this.$refs.form.reset();
      this.branch.latitude = 0;
      this.branch.longitude = 0;
    },
    async createBranch() {
      this.loading = true;
      try {
        await this.$axios.post("organizations/branches/create/", this.branch);
        this.$root.$emit("showCustomSuccess", "Филиал успешно создан!");
        this.$emit("refreshData");
      } catch (result) {
        this.$root.$emit("showCustomError", result);
      } finally {
        this.resetState();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.create-item {
  width: 240px;
  height: 41px;

  font-family: Gilroy-Medium, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #ffffff;
  text-transform: none !important;
}
.dialog-title {
  font-family: Gilroy-Medium, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 31px;
  text-align: center;
  color: #3474d3;
}
.preview {
  position: relative;
  height: 125px;
  width: 125px;

  .delete-btn {
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>
