import Vue from "vue";
import Vuex from "vuex";
import {
  updateAuthorizationHeader,
  removeAuthorizationHeader,
} from "@/plugins/axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    tokens: {
      access: localStorage.getItem("access"),
      refresh: localStorage.getItem("refresh"),
    },
    user: {
      isAuthenticated: false,
      id: "",
      privileges: {},
      organization: {},
      branch: {},
      group: {},
      username: "",
      first_name: "",
      last_name: "",
      telegram_id: "",
      iin: "",
    },
  },
  mutations: {
    saveTokens(state, payload) {
      state.tokens.access = payload.access;
      state.tokens.refresh = payload.refresh;
      state.user.isAuthenticated = true;
      localStorage.setItem("access", payload.access);
      localStorage.setItem("refresh", payload.refresh);
      updateAuthorizationHeader(payload.access);
    },
    removeTokens(state) {
      state.tokens = {
        access: null,
        refresh: null,
      };
      localStorage.clear();
      removeAuthorizationHeader();
    },
    saveUserProfile(state, payload) {
      state.user.id = payload.id;
      state.user.privileges = payload.privileges;
      state.user.organization = payload.organization;
      state.user.branch = payload.branch;
      state.user.groups = payload.groups;
      state.user.username = payload.username;
      state.user.first_name = payload.first_name;
      state.user.last_name = payload.last_name;
      state.user.telegram_id = payload.telegram_id;
      state.user.iin = payload.iin;
    },
  },
  actions: {
    saveTokens({ commit }, payload) {
      commit("saveTokens", payload);
    },
    removeTokens({ commit }) {
      commit("removeTokens");
    },
    saveUserPrivileges({ commit }, payload) {
      commit("saveUserPrivileges", payload);
    },
  },
  getters: {
    gerUserPrivileges(state) {
      return state.user.privileges;
    },
  },
  modules: {},
});
