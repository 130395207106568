<template>
  <v-dialog width="500" v-model="dialog" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn @click="copyObject" v-bind="attrs" v-on="on" icon>
        <v-icon color="blue">mdi-square-edit-outline</v-icon>
      </v-btn>
    </template>
    <v-card class="rounded-lg" style="overflow: hidden">
      <v-card-title class="pb-0">
        <v-row align="center" justify="space-between">
          <v-col class="px-0" cols="auto"></v-col>
          <v-col class="px-0" cols="auto">
            <p class="dialog-title">Редактирование группы</p>
          </v-col>
          <v-col class="px-0" cols="auto">
            <v-btn icon @click="resetState">
              <v-icon size="30" color="error">mdi-close-thick</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text style="padding: 15px 40px">
        <v-form v-model="valid" valid>
          <div class="input-wrapper">
            <label for="groups_key"
              >Наименование группы<span class="red--text">*</span>
            </label>
            <v-text-field
              autocomplete="off"
              v-model="groups.title"
              id="groups_key"
              :rules="rules.required"
              outlined
              dense
            />
          </div>
          <div class="input-wrapper">
            <label for="branch_id"
              >Филиал <span class="red--text">*</span>
            </label>
            <v-select
              :items="branch"
              v-model="groups.branch_id"
              id="branch_id"
              item-text="title"
              item-value="id"
              :rules="rules.required"
              outlined
            ></v-select>
          </div>
          <v-btn
            @click="updateGroups"
            :disabled="!valid"
            class="save-btn"
            depressed
            >Сохранить</v-btn
          >
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "UpdateGroups",
  props: {
    branch: {
      type: Array,
      required: true,
    },
    groupsDefault: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      groups: {},
      dialog: false,
      valid: false,
      rules: {
        required: [(v) => !!v || "Поле обязательно для заполнения"],
      },
    };
  },
  methods: {
    copyObject() {
      Object.assign(this.groups, this.groupsDefault);
      delete this.groups.branch;
      this.groups.branch_id = this.groupsDefault.branch.id;
    },
    resetState() {
      this.groups = {};
      this.dialog = false;
    },
    async updateGroups() {
      try {
        await this.$axios.post(`groups/${this.groups.id}/update/`, this.groups);
        this.$root.$emit("showCustomSuccess", "Группа успешно изменёна!");
        this.$emit("refreshData");
      } catch (result) {
        this.$root.$emit("showCustomError", result);
      } finally {
        this.resetState();
      }
    },
  },
};
</script>

<style scoped></style>
