<template>
  <v-container fluid class="px-5">
    <v-row class="options" align="center">
      <v-col cols="auto" class="pl-7">
        <p class="title">Расписание</p>
      </v-col>
      <div style="width: 110px">
        <v-col cols="12">
          <div class="input-wrapper">
            <v-select
              :items="pagination.limits"
              v-model="pagination.limit"
              hide-spin-buttons
              hide-selected
              hide-details
              outlined
            />
          </div>
        </v-col>
      </div>
      <v-spacer />
      <v-col cols="auto">
        <CreateSchedules
          @refreshData="getSchedules"
          :groups="groups"
          v-if="canUser('schedule-c')"
        />
      </v-col>
    </v-row>
    <v-card-title>
      <v-row justify="start" align="center">
        <v-col cols="auto" class="filter_font">Группа:</v-col>
        <v-col cols="auto">
          <div class="input-wrapper">
            <v-select
              style="width: 333px"
              :items="groups"
              v-model="group_id"
              @change="getSchedules(1)"
              item-text="title"
              item-value="id"
              clearable
              hide-selected
              hide-details
              outlined
            ></v-select>
          </div>
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table
      class="elevation-1"
      :headers="headers"
      :items="schedules"
      :items-per-page="pagination.limit"
      :loading="loading"
      :search="search"
      loading-text="Пожалуйста подождите, данные загружаются..."
      hide-default-footer
    >
      <template v-slot:item.is_day_off="{ item }">
        <p>{{ item.is_day_off === true ? "Да" : "Нет" }}</p>
      </template>
      <template v-slot:item.actions="{ item }">
        <DetailSchedules
          v-if="canUser('schedule-ra')"
          :group_id="group_id"
          :schedule_id="item.id"
        />

        <UpdateSchedules
          v-if="canUser('schedule-u')"
          @refreshData="getSchedules"
          :schedules-default="item"
          :groups="groups"
          :group_id="group_id"
        />

        <delete-item
          v-if="canUser('schedule-d')"
          :delete-item="deleteItem(item)"
          @deleteBranch="deleteSchedule(item)"
        />
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import CreateSchedules from "../components/Schedules/CreateSchedules";
import DeleteItem from "@/components/DeleteItem";
import UpdateSchedules from "../components/Schedules/UpdateSchedules";
import DetailSchedules from "../components/Schedules/DetailSchedules";
export default {
  name: "Schedules",
  components: { DetailSchedules, UpdateSchedules, CreateSchedules, DeleteItem },
  data: () => {
    return {
      schedules: [],
      groups: [],
      loading: false,
      params: {
        groups_id: null,
      },
      headers: [
        {
          text: "Группа",
          align: "start",
          sortable: true,
          value: "group.title",
        },
        // {
        //   text: "Группа",
        //   align: "start",
        //   sortable: true,
        //   value: "group.title",
        // },
        // {
        //   text: "Филиал",
        //   align: "start",
        //   sortable: true,
        //   value: "branch.title",
        // },
        {
          text: "День недели",
          align: "start",
          sortable: true,
          value: "weekday",
        },
        {
          text: "Начало",
          align: "start",
          sortable: true,
          value: "starts_at",
        },
        {
          text: "Конец",
          align: "start",
          sortable: true,
          value: "ends_at",
        },
        {
          text: "Выходной",
          align: "start",
          sortable: true,
          value: "is_day_off",
        },
        {
          text: "Действие",
          align: "center",
          sortable: false,
          value: "actions",
        },
      ],
      pagination: {
        limit: 10,
        limits: [10, 50, 100],
        offset: 0,
        count: 200,
        page: 1,
        pages: 10,
      },
      search: "",
      branch: [],
      group_id: null,
    };
  },
  methods: {
    async getSchedules(page = 1) {
      this.loading = true;
      this.schedules = [];
      this.pagination.page = page;
      this.pagination.offset =
        (this.pagination.page - 1) * this.pagination.limit;
      try {
        const response = await this.$axios.get(
          `groups/${this.group_id}/schedules/`,
          {
            params: {
              limit: this.pagination.limit,
              offset: this.pagination.offset,
            },
          }
        );
        this.schedules = response.data.results;
        this.branch = response.data.results["groups"];
        this.loading = false;
        this.pagination.count = response.data.count;
        this.pagination.pages =
          Math.floor(this.pagination.count / this.pagination.limit) + 1;
      } catch (e) {
        this.loading = false;
        this.$root.$emit("showDefaultError");
      }
    },
    async getGroups() {
      try {
        const response = await this.$axios.get("groups/short/");
        this.groups = response.data;
        if (response.data.length > 0 && !this.group_id)
          this.group_id = response.data[0].id;
      } catch (e) {
        this.$root.$emit("showCustomError", `Ошибка при получение групп`);
      }
    },
    async deleteSchedule(item) {
      try {
        await this.$axios.post(
          `groups/${item.group.id}/schedules/${item.id}/delete/`
        );
        this.$root.$emit("showCustomSuccess", "Филиал успешно удален!");
        await this.getSchedules(this.pagination.page);
      } catch (e) {
        this.$root.$emit(
          "showCustomError",
          `Ошибка во время удаления филиала ${item.id}`
        );
      }
    },
    deleteItem(item) {
      return {
        name: item.id,
        action: "deleteBranch",
      };
    },
  },
  async created() {
    await this.getGroups();
    await this.getSchedules();
  },
};
</script>

<style scoped></style>
